.Jobs {
  min-height: 900px;
  height: calc(100vh - 80px);
  padding-top: 20px;
  background-color: #f2f4f8;
  overflow-y: auto;

  &.nominheight {
    min-height: 0;
  }
}

@media (max-width: 1024px) {
  .Jobs {
    min-height: unset;
    height: unset;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;

    &.postJob {
      padding-bottom: 0;
    }
  }
}
