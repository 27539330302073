.RightPanelHeader {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding-top: 8px;
  padding-left: 35px;
  padding-right: 40px;
  border: 3px solid #f8f9fb;
  border-left-width: 1px;

  .col {
    display: flex;

    &.searchInputContainer {
      justify-content: flex-end;
      width: 350px;
    }

    .noAvatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 50%;
    }

    .userPhoto {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
    }

    .user {
      margin-left: 15px;
      display: flex;
      gap: 13px;
      align-items: flex-start;

      .userInfo {
        h4 {
          margin-bottom: 0;
          font-family: 'Gilroy-SemiBold';
          font-size: 24px;
          line-height: 29px;
          letter-spacing: 0.03em;
        }
  
        .location {
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.03em;
          color: #7a7a7a;
        }
      }

      .userFav {
        margin-top: 4px;
      }
      
      .profileButton {
        position: relative;
        top: 3px;
        width: 100px;
        height: 28px;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: white;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 16px;
        border: none;
      }
    }



    .callButtonsContainer {
      display: flex;
      gap: 10px;
      margin-left: 10px;
      button {
        width: 48px;
        height: 48px;
        font-size: 0;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        border: none;

        &.videoButton {
          background-image: url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3581_61579)'%3E%3Cpath d='M30.3231 9.99219L21.2085 16.5026L30.3231 23.013V9.99219Z' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.6043 7.38281H4.28141C2.84317 7.38281 1.67725 8.54874 1.67725 9.98698V23.0078C1.67725 24.4461 2.84317 25.612 4.28141 25.612H18.6043C20.0426 25.612 21.2085 24.4461 21.2085 23.0078V9.98698C21.2085 8.54874 20.0426 7.38281 18.6043 7.38281Z' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3581_61579'%3E%3Crect width='31.25' height='31.25' fill='white' transform='translate(0.375 0.875)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"),
            linear-gradient(0deg, #f9fafc, #f9fafc);
        }

        &.audioButton {
          background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.4992 19.7545V23.5254C26.5006 23.8755 26.4289 24.222 26.2887 24.5428C26.1484 24.8635 25.9428 25.1515 25.6848 25.3881C25.4268 25.6248 25.1223 25.805 24.7907 25.9171C24.459 26.0293 24.1076 26.0709 23.759 26.0394C19.891 25.6191 16.1755 24.2974 12.9111 22.1805C9.87402 20.2505 7.29908 17.6756 5.36917 14.6385C3.24483 11.3593 1.9228 7.62573 1.5102 3.74037C1.47879 3.39277 1.5201 3.04243 1.6315 2.71167C1.7429 2.38091 1.92196 2.07697 2.15726 1.81921C2.39256 1.56144 2.67896 1.35549 2.99822 1.21447C3.31748 1.07345 3.6626 1.00045 4.01162 1.00012H7.7826C8.39263 0.994118 8.98402 1.21014 9.44656 1.60792C9.90909 2.0057 10.2112 2.5581 10.2966 3.16215C10.4557 4.36895 10.7509 5.55386 11.1765 6.6943C11.3456 7.14421 11.3822 7.63317 11.2819 8.10324C11.1817 8.57331 10.9488 9.00479 10.6108 9.34656L9.01445 10.9429C10.8039 14.0899 13.4095 16.6955 16.5564 18.4849L18.1528 16.8885C18.4946 16.5506 18.926 16.3177 19.3961 16.2174C19.8662 16.1171 20.3551 16.1537 20.805 16.3229C21.9455 16.7484 23.1304 17.0436 24.3372 17.2028C24.9478 17.2889 25.5054 17.5965 25.9041 18.0669C26.3027 18.5374 26.5145 19.138 26.4992 19.7545Z' stroke='%23242833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"),
            linear-gradient(0deg, #f9fafc, #f9fafc);
        }
      }
    }

    .dotsButtonContainer {
      margin-left: 9px;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f9fafc;
      border-radius: 50%;

      .dotsButton {
        width: 48px;
        height: 48px;
        font-size: 0;
        background-image: url(../../../../../assets/images/chat/dots.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        border: none;
      }
    }
  }
}
