.RequestAccessPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .container {
    position: relative;
    top: -76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding-left: 50px;
    padding-right: 50px;
    max-height: calc(100vh - 104px);
    overflow: visible;
    overflow-y: auto;

    h1 {
      width: 100%;
      margin-bottom: 20px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      text-align: left;
      color: #242833;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 50px;
      width: 100%;
      .shortInputsContainer {
        display: flex;
        justify-content: space-between;

        .inputContainer {
          width: 180px;
        }
      }

      button {
        margin-top: auto;
      }
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .RequestAccessPage {
    .container {
      top: 0;
      padding-bottom: 40px;
    }
  }
}
