.TextBox {
  min-height: 155px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  line-height: 16px;
  overflow-y: auto;
  scrollbar-width: thin;
  word-wrap: anywhere;

  a[data-type='fileAttachment'] {
    img {
      display: none;
    }
  }

  .quote {
    position: relative;
    padding-top: 22px;
    padding-bottom: 15px;
    padding-left: 33px;
    padding-right: 33px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 16px;

    &::before {
      content: '‘';
      position: absolute;
      top: -38px;
      left: 16px;
      font-family: 'Gilroy-Medium';
      font-size: 63px;
      line-height: 150%;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      transform: rotate(-180deg);
    }
  }

  &:empty:before {
    content: 'Enter message here';
    color: #c1c5d6;
  }

  &:focus {
    outline: none;
  }
}

@media (max-width: 1024px) {
  .TextBox {
    min-height: 198px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
