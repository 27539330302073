.SignInMethodPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .container {
    position: relative;
    top: -76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 388px;

    h1 {
      margin: 0;
      margin-bottom: 77px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      color: #242833;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 77px;
    }

    .info {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;
      color: #c1c5d6;
      white-space: nowrap;
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .SignInMethodPage {
    .container {
      top: 0;
      padding-bottom: 40px;
    }
  }
}
