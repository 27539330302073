.ViewIcon {
  img {
    max-width: 16px;
    max-height: 16px;
  }
}

@media (max-width: 1024px) {
  .ViewIcon {
    img {
      width: 16px !important;
      height: 16px;
      min-width: 16px !important;
      object-fit: contain;
    }
  }
}
