.MessageInput {
  display: flex;
  gap: 21px;
  padding-top: 46px;
  padding-right: 30px;

  .sendButton {
    width: 60px;
    height: 60px;
    font-size: 0;
    background-image: url(../../../../../../assets/images/chat/send.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 228, 172, 0.92);
    border-radius: 20px;
    border: none;
    backdrop-filter: blur(13.8632px);

    &:disabled {
      background-color: rgba(193, 197, 214, 0.92);
    }
  }
}
