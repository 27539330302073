.Profile {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .row {
    width: 100%;
    display: flex;
    gap: 50px;

    &.buttonsRow {
      flex: 1;
      justify-content: flex-end;
      margin-top: 50px;
    }

    .title {
      margin-bottom: 30px;
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      color: #242833;
    }

    .avatar {
      max-width: 120px;
      margin-bottom: 30px;
      display: flex;
      gap: 56px;

      .container {
        position: relative;

        .photo,
        .addPhoto {
          position: relative;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          object-fit: cover;
        }

        .deletePhoto {
          position: absolute;
          top: 12px;
          right: 0;
          width: 25px;
          height: 25px;
          background: url('../../../../assets/images/dashboard/profile-settings/closeIcon.svg');
          cursor: pointer;
        }

        .addPhoto {
          display: flex;
          padding-top: 48px;
          align-items: center;
          border: 2px dashed #c1c5d6;
          background: white;
          font-family: 'Gilroy-Bold';
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: #c1c5d6;
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            top: 25px;
            left: 50%;
            width: 30px;
            height: 30px;
            transform: translateX(-50%);
            background: url('../../../../assets/images/dashboard/profile-settings/addIcon.svg');
          }
        }

        .file {
          display: none;
        }
      }
    }
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .emailDisabled {
      position: relative;
      pointer-events: none;
    }
  }

  .noActive {
    pointer-events: none;
  }

  .buttons {
    margin-top: auto;
    left: 50%;
    bottom: 0;
    display: flex;
    gap: 30px;
  }
}

@media (max-width: 1024px) {
  .Profile {
    .row {
      flex-direction: column;
    }

    .column {
      gap: 50px;
    }
  }
}
