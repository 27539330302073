.PollForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 42px;
}

@media (max-width: 1024px) {
  .PollForm {
    padding-right: 0;
  }
}
