.Image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    background-color: rgba(36, 40, 51, 0.5);
  }

  .mediaLeft {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Bold';
    font-size: 24px;
    line-height: 150%;
    color: white;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1024px) {
  .Image {
    aspect-ratio: 375 / 460;
    min-width: 100%;
  }
}
