.GridView {
  max-width: calc(100vw - 146px);
  max-height: calc(100vh - 80px - 37px - 154px - 20px - 53px - 95px - 46px);
  padding-bottom: 16px;
  overflow: hidden;

  .container {
    display: flex;
    gap: 14px;
    height: 100%;
    padding-bottom: 19px;
    overflow-y: auto;
    scrollbar-color: #00e4ac transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00e4ac;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f2f4f8;
    }
  }
}

@media (max-width: 1024px) {
  .GridView {
    max-width: unset;
    max-height: unset;
    height: unset;
    overflow: unset;

    .container {
      gap: 20px;
      padding-bottom: 14px;
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}
