.PasswordSignInPage {
  display: flex;
  align-items: center;
  flex: 1;

  .container {
    position: relative;
    top: -42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    flex: 1;
    max-height: calc(100vh - 104px);
    overflow-y: auto;

    h1 {
      width: 400px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      color: #242833;
      margin-bottom: 333px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 47px;
      width: 400px;
      margin-bottom: 14px;
    }

    .helper {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #c1c5d6;

      a {
        text-decoration: none;
      }

      span {
        color: #00e4ac;
        cursor: pointer;
      }
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .PasswordSignInPage {
    .container {
      top: 0;
      padding-bottom: 40px;

      h1 {
        margin-bottom: 100px;
      }
    }
  }
}
