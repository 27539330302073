.CandidateMenu {
  position: relative;

  .optionsContainer {
    position: absolute;
    z-index: 1112;
    transform: translateX(calc(-100% + 10px));
    width: 180px;
    padding-bottom: 50px;

    .options {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
      padding-top: 19px;
      padding-bottom: 16px;
      padding-left: 13px;
      padding-right: 4px;
      list-style: none;
      background: white;
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
      border-radius: 16px 0px 16px 16px;

      &.onlyStandardOptions {
        li {
          &.disabled {
            &::after {
              display: none;
            }
          }
        }
      }

      li {
        width: 163px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #3c3c43;

        &.disabled {
          position: relative;
          color: #c1c5d6;

          &::after {
            content: '';
            position: absolute;
            top: -9px;
            left: 23px;
            display: block;
            width: 100px;
            height: 1px;
            background-color: #c1c5d6;
          }
        }
      }
    }
  }
}

.candidatePopup {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-left: 15px;

  .selector {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-weight: bold;
  }

  .optionsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .options {
      position: absolute;
      z-index: 1000;
      top: calc(100% + 5px);
      right: 0;
      width: 180px;
      height: max-content;
      background-color: white;
      box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        height: 38px;
        padding-left: 20px;
        font-family: 'Gilroy-Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #242833;
        cursor: pointer;

        &:hover {
          font-family: 'Gilroy-Bold';
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          color: #141224;
          background-color: #f2f4f8;
        }

        &:first-child:hover {
          border-radius: 16px 16px 0 0;
        }

        &:last-child:hover {
          border-radius: 0 0 16px 16px;
        }

        &:first-child:last-child {
          border-radius: 16px;
        }

        &.lastItemLine:last-child {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 50%;
            display: block;
            width: 100px;
            height: 1px;
            background-color: #c1c5d6;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
