.FormDropdown {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: -140px;
  top: 40px;


  &:focus {
    outline: none;

    .selector {
      background-color: white;
      box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.12);
    }
  }

  &.open {
    .selector {
      z-index: 1001;
      background-color: white;
      box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.12);

      .arrow {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999985 8.60864L7.99998 1.30429L15 8.60864' stroke='%23242833' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .searchAndOptions {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    left: 0;
    z-index: 1001;
    background-color: transparent;
  }

  .selector {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 48px;
    padding-left: 20px;
    padding-right: 78px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 100%;
    color: #c1c5d6;
    background-color: #f2f4f8;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      background-color: white;
      box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.12);
    }

    .hasOption {
      color: #242833;
      white-space: nowrap;
    }

    .arrow {
      position: absolute;
      right: 35px;
      width: 16px;
      height: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.39136L8.00002 8.69571L1.00002 1.39136' stroke='%2300E4AC' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
    height: 55px;
    margin-bottom: 5px;
    padding-left: 28px;
    padding-right: 13px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);

    input {
      width: 100%;
      height: 100%;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 19px;
      background-color: transparent;
      border: none;

      &::placeholder {
        color: #c1c5d6;
      }

      &:focus {
        outline: none;
      }
    }
    i {
      display: block;
      width: 32px;
      height: 32px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.5' width='32' height='32' rx='16' fill='%23F2F4F8'/%3E%3Cpath d='M16 10H16.05' stroke='%23242833' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 22V14' stroke='%23242833' stroke-width='2.5' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      cursor: pointer;
    }
  }

  .options {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);

    ul {
      // max-height: 369px;
      max-height: 200px;
      overflow-y: auto;
      margin: 0;
      padding: 0;
      list-style: none;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: #00e4ac;
        border-radius: 6px;
      }

      li {
        display: flex;
        align-items: center;
        gap: 15px;
        height: 38px;
        padding-left: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
        cursor: pointer;

        &.danger {
          color: #ff2d55;
        }

        &.active {
          font-size: 16px;
          line-height: 24px;
          color: #00e4ac;
        }

        &:hover {
          font-size: 18px;
          line-height: 24px;
          font-family: 'Gilroy-Bold';
          color: #141224;
          background-color: #f2f4f8;
        }
      }
    }
  }
}
