.AddComment {
  position: sticky;
  bottom: 5px;
  z-index: 1;
  display: flex;
  gap: 16px;
  margin-top: 17px;

  .container {
    position: relative;
    width: calc(100% - 16px - 60px);
    padding: 12px;
    min-height: 60px;
    display: flex;
    align-items: center;
    background: #f2f4f8;
    opacity: 0.92;
    border-radius: 20px;

    .addUsers {
      flex-shrink: 0;
      align-self: flex-start;
      width: 35px;
      height: 35px;
      background: url('../../../../../../../assets/images/dashboard/discover/comments/add.svg');
      cursor: pointer;
    }

    .content {
      padding: 0px 18px 0 18px;
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;

      .userReply {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }

      .textBox {
        flex-grow: 1;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 25px;
        color: black;
        background-color: inherit;
        word-break: break-word;
        outline: none;
        border: none;
        resize: none;

        &:empty:before {
          content: 'Say something...';
          color: #b1b6ca;
        }
      }
    }

    .emodji {
      flex-shrink: 0;
      margin-top: 3px;
      width: 30px;
      height: 30px;
      align-self: flex-start;
      background: url('../../../../../../../assets/images/dashboard/discover/comments/smile.svg');
      cursor: pointer;
    }

    .charsLeft {
      position: absolute;
      bottom: calc(100% + 10px);
      right: 0;
      font-family: 'Gilroy-Regular';
      font-size: 12px;
      line-height: 20px;
      color: #c1c5d6;

      &.red {
        color: #ff2d55;
      }
    }
  }

  .sent {
    position: relative;
    width: 60px;
    height: 60px;
    background: #c1c5d6;
    opacity: 0.92;
    border-radius: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 27px;
      height: 27px;
      background: url('../../../../../../../assets/images/dashboard/discover/comments/sent.svg');
      transform: translate(-50%, -50%);
    }

    &.active {
      background: #00e4ac;
      cursor: pointer;
    }
  }
}

.emojiPicker {
  position: fixed;
  z-index: 3;
}

@media (max-width: 1024px) {
  .AddComment {
    margin-top: 30px;
    .container {
      .addUsers {
      }

      .content {
        .userReply {
        }

        .textBox {
          &:empty:before {
          }
        }
      }

      .emodji {
      }

      .charsLeft {
        &.red {
        }
      }
    }

    .sent {
      &::after {
      }

      &.active {
      }
    }
  }

  .emojiPicker {
  }
}
