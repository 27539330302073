.dialog {
  max-width: 871px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  height: 730px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.PostJobSuccessModal {
  .content {
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 29px;
      padding-top: 30px;

      h1 {
        margin-bottom: 15px;
        font-family: 'Gilroy-Bold';
        font-size: 50px;
        text-align: center;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .live {
        font-family: 'Gilroy-Bold';
        font-size: 35px;
        line-height: 30px;
        text-align: center;
        color: #242833;
      }

      .closeIcon {
        position: absolute;
        top: 17px;
        right: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: rgba(193, 197, 214, 0.5);
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .jobDetails {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-left: 36px;
      padding-right: 36px;

      & > div:first-child {
        word-break: break-word;
      }

      & > div:last-child {
        margin-top: 6px;
        flex-shrink: 0;
      }

      .favoriteIconContainer {
        cursor: pointer;
      }

      h2 {
        margin: 0;
        display: flex;
        gap: 10px;
        font-family: 'Gilroy-SemiBold';
        font-size: 35px;
        line-height: 36px;
        color: #242833;
      }

      .location {
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #242833;
      }

      h3 {
        margin: 0;
        font-family: 'Gilroy-Medium';
        font-size: 22px;
        line-height: 22px;
        text-align: right;
        color: #242833;
      }

      .jobId {
        display: inline-block;
        width: 100%;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 24px;
        text-align: right;
        color: #242833;
      }
    }

    .settings {
      display: flex;
      justify-content: space-between;
      margin-bottom: 66px;
      padding-left: 36px;
      padding-right: 36px;

      .share {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .selectContainer {
          display: flex;
          gap: 15px;
          align-items: flex-end;
        }
      }

      .qrContainer {
        .qrInnerContainer {
          position: relative;
          display: flex;
          gap: 13px;
          flex-direction: column;
          align-items: center;
          width: 240px;
          height: 273px;
          margin-bottom: 9px;
          background-color: #f2f4f8;
          border-radius: 12px;
          cursor: pointer;

          .qr {
            margin-top: 27px;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border: 1px solid #242833;
            border-radius: 12px;
          }

          h4 {
            width: 100%;
            padding: 0 15px;
            font-family: 'Gilroy-Bold';
            font-size: 18px;
            line-height: 150%;
            text-align: center;
            color: #242833;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .tip {
          display: inline-block;
          width: 100%;
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #242833;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding-left: 36px;
      padding-right: 36px;
      background-color: #f2f4f8;
      box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
      border-radius: 0px 0px 20px 20px;

      .socialButtonsContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 17px;
        padding-bottom: 24px;

        h4 {
          margin: 0;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
        }

        .buttons {
          display: flex;
          gap: 20px;

          i {
            cursor: pointer;
          }
        }
      }

      .copyButtonContainer {
        padding-top: 26px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 167px;
          height: 50px;
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 24px;
          color: #242833;
          background-color: white;
          border-radius: 16px;
          border: none;
        }
      }
    }
  }
}
