.SideMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
  width: 80px;
  max-height: 100vh;
  padding-top: 15px;
  padding-bottom: 29px;
  background-color: white;
  border-right: 1px solid #f2f4f8;
  overflow-y: auto;
  overflow-x: hidden;

  .topMenuContainer {
    display: none;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 54px;
    img {
      width: 60px;
      height: 19px;
      margin-bottom: 5px;
    }

    .powered {
      font-family: 'Gilroy-Regular';
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #c1c5d6;
    }

    .zaphire {
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 15px;
      font-weight: bold;
      color: #242833;
    }
  }

  nav {
    margin-bottom: 90px;
    .buttonsList {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .bottomBlock {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: auto;

    i {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-size: contain;
      cursor: pointer;

      &.options {
        background-image: url(../../../assets/images/dashboard/nav/options.svg);
      }

      &.activeOptions {
        background-image: url(../../../assets/images/dashboard/nav/options-active.svg);
      }

      &.logout {
        background-image: url(../../../assets/images/dashboard/nav/logout.svg);
      }
    }
  }
}

@media (max-width: 1024px) {
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: #242833;
    opacity: 0.2;
  }

  .SideMenu {
    position: fixed;
    right: -80px;
    top: 0;
    bottom: 0;
    z-index: 10001;
    max-height: 100dvh;
    transition: right 0.3s;

    &.open {
      right: 0;
    }

    .topMenuContainer {
      display: unset;
      padding-top: 20px;
    }

    .bottomBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60px;
      padding-bottom: 20px;
      border-bottom: 1.3px solid #242833;
    }
  }
}
