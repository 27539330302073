.BrandKit {
  display: flex;
  max-width: calc(100vw - 152px);
  gap: 32px;
  height: 100%;
  padding-top: 29px;
  padding-left: 20px;
  padding-bottom: 17px;
  padding-right: 19px;
}

@media (max-width: 1024px) {
  .BrandKit {
    flex-direction: column;
    max-width: unset;
    height: unset;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
  }
}
