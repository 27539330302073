.noData {
  margin-top: 14px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 21px;
  color: #c1c5d6;
}

.interviews {
  margin-top: -15px;

  .container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .title {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
  }

  .amount {
    width: 48px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    background: #00e4ac;
    border-radius: 16px;
  }

  .interviewList {
    height: 315px;
    margin-top: 13px;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .interview {
    margin: 0 15px 23px 0;

    &:last-child {
      margin-bottom: -20px;
    }
  }
  .upper {
    display: flex;
    justify-content: space-between;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #b1b6ca;

    .settings {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      .rating,
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: 'Gilroy-SemiBold';
        font-size: 14px;
        line-height: 24px;
        color: white;
        background: #00e4ac;
        border-radius: 16px;
      }
      .rating {
        width: 48px;
        height: 30px;
        margin-right: 50px;
      }

      .noPass {
        background: #ff2d55;
      }

      .status {
        width: 160px;
        height: 30px;
        margin-right: 60px;
        font-size: 16px;
      }

      .scheduled {
        background-color: #6722d2;
      }

      .declined {
        background-color: #ffbf00;
      }

      .pass,
      .accepted {
        background-color: #00e4ac;
      }

      .fail {
        background-color: #ff2d55;
      }

      .edit,
      .delete {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }

      .edit {
        margin-right: 20px;
        background: url('../../../../../assets/images/dashboard/candidate/header/edit.svg');
        background-position: -10px;
      }

      .delete {
        background: url('../../../../../assets/images/dashboard/candidate/close.svg');
        background-position: -10px;
      }
    }
  }

  .lower {
    display: flex;
    gap: 10px;
    width: 1012px;
    height: 97px;
    padding: 0 21px 0 25px;
    background: #f2f4f8;
    border-left: 21px solid #00e4ac;
    border-radius: 16px;

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      flex-grow: 1;
      flex-shrink: 0;
      line-height: 24px;

      .name,
      .description {
        margin: 0;
      }

      .name {
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 20px;
        color: #3c3c43;
      }

      .description {
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: #707070;
      }
    }

    .leftPanel {
      overflow: hidden;
      flex-shrink: 1;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    button {
      width: 97px;
      height: 60px;
      padding: 4px 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      color: white;
      background: #242833;
      border: 0;
      border-radius: 16px;

      &:disabled {
        background-color: #c1c5d6;
        cursor: default;
      }
    }

    .feedbackButton {
      background-color: #00e4ac;
    }

    .editFeedback {
      background: #242833;
    }
  }

  .scheduled {
    border-left-color: #6722d2;
  }

  .declined {
    border-left-color: #ffbf00;
  }

  .pass,
  .accepted {
    border-left-color: #00e4ac;
  }

  .fail {
    border-left-color: #ff2d55;
  }
}

@media (max-width: 1024px) {
  .noData {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 17px;
  }

  .interviews {
    height: unset;
    margin-top: 0;

    overflow-x: auto;

    .title {
      font-size: 16px;
      line-height: 20px;
    }

    .amount {
      width: 30px;
      height: 20px;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
    }

    .interviewList {
      height: unset;
      overflow-x: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .interview {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .lower {
      margin-top: 20px;
    }

    .upper {
      align-items: center;
      .date {
        margin-right: 70px;
        white-space: nowrap;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
      .settings {
        margin-bottom: 0;
      }
    }
  }
}
