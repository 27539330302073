.Tooltip {
  position: relative;

  .tip {
    position: absolute;
    z-index: 1001;
    top: calc(100% + 12px);
    left: 50%;
    display: inline-flex;
    align-items: center;
    min-height: 27px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 13px;
    text-align: center;
    font-family: 'Gilroy-Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    color: white;
    background-color: #242833;
    box-shadow: 0px -4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &::after {
      content: '';
      position: absolute;
      top: -8.5px;
      left: 0;
      width: 23px;
      height: 17px;
      background-image: url(../../assets/images/tooltip/triangle.png);
      background-repeat: no-repeat;
    }

    &.visible {
      opacity: 1;
    }

    &.top {
      top: unset;
      bottom: calc(100% + 12px);

      &::after {
        top: unset;
        bottom: -8px;
        transform: scaleY(-1);
      }
    }

    &.top {
      &.left {
        &::after {
          transform: scaleY(-1) scaleX(-1);
        }
      }
    }

    &.left {
      transform: translateX(-100%);

      &::after {
        left: unset;
        right: 0;
        transform: scaleX(-1);
      }
    }
  }
}
