.RecentlyViewed {
  padding-top: 22px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 16px;
  background-color: white;
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 16px;

  h2 {
    margin-bottom: 6px;
    font-family: 'Gilroy-Bold';
    font-size: 22px;
    line-height: 24px;
    color: #242833;
  }

  .list {
    overflow-x: auto;

    ul {
      display: flex;
      gap: 33px;
      margin: 0;
      padding: 0;
      padding-top: 13px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 13px;
      list-style: none;
      overflow-y: hidden;
      scrollbar-width: thin;
      scrollbar-color: #00e4ac transparent;

      &::-webkit-scrollbar {
        height: 4px !important;
      }
    }
  }
}
