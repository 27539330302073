.dialog {
  max-width: 1144px;
}

.modalContent {
  border-radius: 16px;
  overflow: hidden;
  border: none;
}

.backdrop {
  background-color: #242833;
  opacity: 0.1 !important;
}

.MediaModal {
  .content {
    display: flex;
    gap: 17px;
    height: 725px;
    padding-top: 48px;
    padding-bottom: 28px;
    border-radius: 16px;

    .closeIcon {
      position: absolute;
      z-index: 1;
      top: 36px;
      right: 22px;
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-image: url(../../assets/images/dashboard/discover/close.svg);
      cursor: pointer;
    }

    .post {
      display: flex;
      flex-direction: column;
      padding-right: 15px;

      .textContainer {
        // overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #00e4ac transparent;
      }

      .postStatsContainer {
        padding-top: 15px;
      }
    }
  }
}
