.RecentlyActivities {
  h2 {
    margin-bottom: 20px;
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 25px;
    color: #242731;
  }

  .content {
    padding-top: 15px;
    padding-left: 19px;
    padding-right: 18px;
    padding-bottom: 15px;
    width: calc(100vw - 99px - 10.5px - 350px);
    min-width: 790px;
    position: relative;
    background-color: white;
    box-shadow: -10px -4px 60px rgba(78, 79, 114, 0.1);
    border-radius: 16px;

    .noData {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-SemiBold';
      font-weight: 600;
      text-align: center;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #c1c5d6;
    }

    .container {
      height: 100%;
      overflow: hidden;
    }

    .cards {
      height: 100%;
      display: flex;
      gap: 22px;
    }

    .nextCard,
    .prevCard {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      right: 6px;
      background: url('../../../../assets/images/dashboard/discover/next.svg');

      cursor: pointer;
    }

    .prevCard {
      right: auto;
      left: 7px;
      background: url('../../../../assets/images/dashboard/discover/prev.svg');
    }
  }
}

@media (max-width: 1024px) {
  .RecentlyActivities {
    width: 100%;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;

    .content {
      width: 100%;
      min-width: unset;
    }
  }
}
