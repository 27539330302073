.candidate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 33px 32px;
  width: 100%;
  height: 206px;
  background-color: white;
  border: 2px solid white;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 16px;

  .close {
    display: none;
  }

  .photoContainer {
    padding: 8px;
    width: 130px;
    height: 130px;
    display: flex;
    flex-shrink: 0;
    border: 2px solid #00e4ac;
    border-radius: 50%;
  }

  .photo {
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .noPhoto {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    color: white;
    border-radius: 50%;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
  }

  .state {
    margin-left: 25px;
    display: flex;
    flex-direction: column;

    .nameAndLocationContainer {
      .nameContainer {
        display: flex;
        align-items: center;
        gap: 11px;
      }
    }
  }

  .name {
    font-family: 'Gilroy-Bold';
    font-size: 26px;
    line-height: 30px;
    color: #242833;
  }

  .location {
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    color: #3c3c43;
  }
}

.spaceBetween {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.steps {
  margin-top: 10px;
  display: flex;
  gap: 41px;
}

.step {
  width: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .currentStep {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c1c5d6;
    text-align: center;
    font-size: 18px;
    border-radius: 50%;
    border: 1px solid #c1c5d6;
  }

  .active {
    border-color: #242833;
    color: #242833;
  }

  .black {
    border: 0;
    background-color: #242833;
    color: white;
  }

  .red {
    background-color: #ff2d55;
    color: white;
    border: 0;
  }

  .yellow {
    background-color: #ffbf00;
    color: white;
    border: 0;
  }

  .green {
    background-color: #00e4ac;
    border-color: #00e4ac;
    color: white;
  }

  .completed {
    position: relative;
    background: no-repeat center
      url('../../../../assets/images/dashboard/candidate/header/check.svg');
    background-color: #00e4ac;
    border-color: #00e4ac;

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 2px;
      right: -40px;
      width: 37px;
      background-color: #00e4ac;
    }
  }

  &:last-child .completed:after {
    content: '';
    display: none;
  }

  .stepName {
    font-family: 'Gilroy-Regular';
    font-size: 10px;
    line-height: 20px;
    color: #707070;
  }
}

.settings {
  margin-left: 150px;

  .menu {
    display: flex;
    align-items: center;
    gap: 14px;

    & > div {
      cursor: pointer;
    }

    .fav,
    .calendar {
      width: 40px;
      height: 40px;
    }

    .calendar {
      background: center no-repeat
          url('../../../../assets/images/dashboard/candidate/header/calendar.svg'),
        #f2f4f8;
      box-shadow: 0px 16px 60px rgba(134, 109, 201, 0.16);
      border-radius: 25px;
    }

    .offCalendar {
      background: center no-repeat
          url('../../../../assets/images/dashboard/candidate/header/offCalendar.svg'),
        #f2f4f8;
      pointer-events: none;
    }

    .status {
      position: relative;
      padding: 0 25px 0 10px;
      width: 180px;
      height: 40px;
      background: #c1c5d6;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .statusTitle {
        margin-left: 15px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: white;
      }

      .invited {
        color: #242833;
      }

      &::after {
        content: '';
        position: absolute;
        right: 10px;
        width: 11px;
        height: 5px;
        background: url('../../../../assets/images/dashboard/candidate/header/downArrow.svg');
      }
    }

    .disabled {
      pointer-events: none;

      &::after {
        content: none;
      }
    }

    .black {
      border: 0;
      background-color: #242833;
      color: white;
    }

    .red {
      background-color: #ff2d55;
      color: white;
      border: 0;
    }

    .yellow {
      background-color: #ffbf00;
      color: white;
      border: 0;
    }

    .green {
      background-color: #00e4ac;
      border-color: #00e4ac;
      color: white;
    }
  }

  .cal {
    margin-top: 45px;
    padding: 0 14px 0 18px;
    width: 345px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Gilroy-Regular;
    background: #f2f4f8;
    border-radius: 16px;

    .category {
      margin: 0;
    }

    .date {
      color: #00e4ac;
    }

    .icons {
      display: flex;
      gap: 8px;
    }

    .eye,
    .edit,
    .closeEye {
      width: 25px;
      height: 26px;
      cursor: pointer;
    }

    .closeEye {
      background: url('./../../../../assets/images/dashboard/candidate/header/closeEye.svg');
    }

    .eye {
      background: url('./../../../../assets/images/dashboard/candidate/header/eye.svg');
    }

    .edit {
      background: url('./../../../../assets/images/dashboard/candidate/header/editCal.svg');
    }
  }
}

.finished {
  background-color: #242833;
  border-color: #242833;

  .name,
  .location,
  .stepName {
    color: white;
  }

  .menu .status {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
  }
}

@media (max-width: 1024px) {
  .candidate {
    flex-direction: column;
    height: unset;
    padding: 15px;

    .close {
      display: unset;
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    .photoContainer {
      width: 60px;
      height: 60px;
      margin-bottom: 15px;
      padding: 2px;
    }

    .noPhoto {
      font-size: 16px;
      line-height: 16px;
      padding-bottom: 1px;
      text-transform: uppercase;
    }

    .state {
      margin-left: 0;
      .nameAndLocationContainer {
        top: 22px;
        left: 85px;
        position: absolute;
      }
    }

    .name {
      margin-bottom: 5px;
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 20px;
      color: #242833;
    }

    .location {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
    }
  }

  .spaceBetween {
    flex-direction: column-reverse;
  }

  .steps {
    width: 100%;
    justify-content: space-between;
    gap: unset;
  }

  .step {
    position: relative;
    .completed {
      &::after {
        left: 33px;
        width: var(--stepsDistance);
      }
    }

    .stepName {
      font-size: 12px;
    }
  }

  .settings {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
    margin-bottom: 15px;

    .menu {
      justify-content: space-between;
      gap: unset;
    }

    .cal {
      width: 100%;
      height: 45px;
      margin-top: 0;
      margin-bottom: 15px;

      .category {
        font-size: 14px;
      }

      .date {
        font-size: 14px;
      }
    }
  }
}
