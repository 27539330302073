.Applications {
  height: 507px;
  margin-right: -30px;
  padding-right: 11px;
  overflow-y: scroll;

  .noData {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242833;
  }
}

@media (max-width: 1024px) {
  .Applications {
    height: unset;
    padding-right: 0;
    margin-right: 0;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
