.TitleWithTooltip {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 13px;

  h2 {
    margin: 0;
    font-family: Gilroy-Bold;
    color: #242833;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
