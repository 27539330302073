.video {
  position: relative;
  height: 534px;
  margin: 8px 0 0 30px;

  .nextVideo,
  .prevVideo {
    position: absolute;
    top: 250px;
    width: 30px;
    height: 30px;
    background: no-repeat center
      url('../../../../../assets/images/dashboard/candidate/pagination.svg');
    background-color: #00e4ac;
    box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
    border-radius: 80px;
    transition: background-color 0.3s;
    border: none;

    &:disabled {
      background-color: #c1c5d6;
    }
  }

  .nextVideo {
    right: -34px;
  }

  .prevVideo {
    left: -34px;
    transform: rotate(180deg);
  }

  video {
    width: 250px;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;

    &:fullscreen {
      object-fit: contain;
    }
  }

  .videoText {
    padding: 0 20%;
    width: 250px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #c1c5d6;
    background-color: #f2f4f8;
    border-radius: 16px;
  }

  .blur {
    position: absolute;
    bottom: 32px;
    right: 18px;
    width: 219px;
    height: 57px;
    background: #000000;
    opacity: 0.5;
    border-radius: 20px;
  }

  .controls {
    position: absolute;
    bottom: 32px;
    right: 18px;
    width: 219px;
    height: 57px;

    .seekbar {
      position: absolute;
      top: 17px;
      left: 7px;
      width: 205px;
      height: 4px;
      background: #65758d;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
    }

    .progress {
      width: 10%;
      height: 4px;
      background: #00e0a2;
      border-radius: 5px;
      overflow: hidden;
    }

    .buttons {
      position: absolute;
      bottom: 7px;
      left: 9px;
      right: 9px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;
    }

    .play {
      width: 15px;
      height: 17px;
      background: url('../../../../../assets/images/dashboard/candidate/details/play.svg');
      cursor: pointer;
    }

    .pause {
      position: relative;
      width: 15px;
      height: 17px;
      background: transparent;

      &::before {
        content: '';
        position: absolute;
        bottom: 6px;
        left: 3px;
        width: 15px;
        height: 3px;
        background-color: white;
        transform: rotate(90deg);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 6px;
        right: 3px;
        width: 15px;
        height: 3px;
        background-color: white;
        transform: rotate(90deg);
      }
    }

    .fullscrean {
      width: 16px;
      height: 13px;
      justify-self: end;
      background: url('../../../../../assets/images/dashboard/candidate/details/fullScrean.svg');
      cursor: pointer;
    }

    .timer {
      justify-self: start;
      font-family: 'Gilroy-SemiBold';
      font-size: 15px;
      color: white;
    }
  }
}

.isOpened {
  display: flex;
  justify-content: center;

  .prevVideo,
  .nextVideo {
    top: auto;
    bottom: 46px;
    right: auto;
  }

  .prevVideo {
    left: calc(50% - 150px);
  }

  .nextVideo {
    right: calc(50% - 150px);
  }

  video {
    height: auto;
    width: auto;
  }

  .controls {
    right: auto;
  }

  .blur {
    right: auto;
  }
}

@media (max-width: 1024px) {
  .video {
    margin: 0;
    height: unset;
    aspect-ratio: 357 / 635;

    .videoText {
      width: 100%;
    }

    video {
      width: 100%;
    }

    .blur {
      width: unset;
      left: 15px;
      right: 15px;
      bottom: 15px;
    }

    .controls {
      left: 15px;
      right: 15px;
      bottom: 15px;
      width: unset;

      .buttons {
        left: 15px;
        right: 15px;
      }

      .seekbar {
        left: 15px;
        right: 15px;
        width: unset;
      }
    }
  }
}
