.RequestNewPasswordSuccessPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .container {
    position: relative;
    top: -76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-height: calc(100vh - 104px);
    overflow-y: auto;
    overflow-x: hidden;

    h1 {
      position: relative;
      width: 100%;
      margin: 0;
      margin-bottom: 36px;
      font-family: 'Gilroy-Bold';
      font-size: 46px;
      line-height: 150%;
      color: #242833;
      white-space: nowrap;
    }

    p {
      height: 363px;
      margin: 0;
      margin-bottom: 75px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: #242833;
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .RequestNewPasswordSuccessPage {
    .container {
      top: 0;
      padding-bottom: 40px;

      p {
        height: 250px;
        margin-bottom: 0;
      }
    }
  }
}
