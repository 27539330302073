.Timeline {
  // padding: 20px 32px;
  padding-left: 32px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  max-height: 650px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .event {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    gap: 5px;

    &:last-child {
      min-height: 0;
    }

    .title {
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.42px;
    }

    .date {
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.14px;
      color: #b1b6ca;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -32px;
      width: 25px;
      height: 25px;
      background: url('../../../../../../../assets/images/dashboard/candidate/timeline/circle.svg');
    }

    &::after {
      content: '';
      position: absolute;
      top: 38px;
      left: -21px;
      height: 67px;
      width: 2px;
      background-color: #70edd4;
    }

    &:last-child {
      &:after {
        content: '';
        display: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Timeline {
    max-height: unset;

    &.candidatePopup {
      white-space: nowrap;
    }
  }
}
