* {
  box-sizing: border-box;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #00e4ac;
    border-radius: 6px;
  }
}

body {
  margin: 0;
}

@font-face {
  font-family: 'National 2 Condensed';
  src: url('./fonts/National2Condensed-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'),
    url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'),
    url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'),
    url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}
