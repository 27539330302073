.JobInfo {
  width: 951px;
  height: 46px;
  padding: 13px;
  margin-bottom: 5px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f4f8;
  border-radius: 16px;

  &.candidateCard {
    padding: 0;
    height: 28px;
    border: none;

    .container {
      width: 100%;
      gap: 10px;
    }
  }

  &.joined {
    background-color: #242833;
    color: white;

    .titleContainer {
      .jobTitle {
        color: white;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .titleContainer {
    display: flex;
    gap: 18px;
    align-items: center;
    overflow: hidden;

    .jobTitle {
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .jobId {
      margin-left: -10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .container {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  .timeAgo {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .createdAt {
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;

      &.new {
        color: #242833;
      }
    }
  }

  .statusContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .status {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 24px;
      color: #00e4ac;

      &.joined {
        background: linear-gradient(
          228deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .date {
    width: 95px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 24px;
    color: #c1c5d6;
  }
}

@media (max-width: 1024px) {
  .JobInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: unset;
    height: unset;
    padding-top: 11px;
    padding-left: 15px;
    padding-bottom: 13px;
    padding-right: 11px;

    &.candidateCard {
      .container {
      }
    }

    &.joined {
      .titleContainer {
        .jobTitle {
        }
      }
    }

    &:last-of-type {
    }

    .titleContainer {
      .jobTitle {
      }

      .jobId {
      }
    }

    .container {
      align-items: center;
      gap: 5px;

      .resume {
        position: relative;
        top: -2px;
      }
    }

    .timeAgo {
      .createdAt {
        &.new {
        }
      }
    }

    .statusContainer {
      .status {
        &.joined {
        }
      }
    }

    .date {
    }
  }
}
