.CandidateCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 193px;
  height: 171px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  box-shadow: 0px -4px 60px rgba(62, 63, 99, 0.1);
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  .photContainer {
    position: relative;
    margin-bottom: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 62px;
      right: 7px;
      display: block;
      width: 8px;
      height: 8px;
      background-color: #ff2d55;
      border-radius: 50%;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .name {
    margin-bottom: 4px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 18px;
    color: #3c3c43;
  }

  .location {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 19px;
    color: #b4b1b1;
  }
}
