.SetNewPasswordPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .container {
    position: relative;
    top: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 470px;
    padding-left: 35px;
    padding-right: 35px;
    max-height: calc(100vh - 104px);
    overflow-y: auto;

    h1 {
      margin: 0;
      margin-bottom: 53px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      color: #242833;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 80px;

      button {
        margin-top: 5px;
      }
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .SetNewPasswordPage {
    .container {
      top: 0;
      padding-bottom: 40px;
    }
  }
}
