.AuthHeading {
  margin: 0;
  font-family: 'Gilroy-Bold';
  font-size: 48px;
  line-height: 150%;
  color: #242833;

  img {
    width: 135px;
    height: 40px;
  }

  span {
    font-weight: 400;
    color: #c1c5d5;
  }
}
