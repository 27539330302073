.Video {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 0;
    background-color: transparent;
    border: none;
  }
}

@media (max-width: 1024px) {
  .Video {
    aspect-ratio: 375 / 460;
    min-width: 100%;
  }
}
