.Stat {
  position: relative;
  padding: 15px;
  width: 175px;
  height: 162px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .title {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 20px;
  }

  .container {
    display: flex;
    justify-content: space-between;

    .amount {
      position: relative;
      top: 10px;
      font-family: 'Gilroy-SemiBold';
      font-size: 24px;
      line-height: 17px;
      color: #6941ff;
    }

    .percentage {
      &::after {
        content: '%';
        font-size: 16px;
      }
    }

    .minutes {
      &::before {
        content: 'In minutes';
        position: absolute;
        top: 28px;
        left: 0;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 10px;
        line-height: 160%;
        white-space: pre;
        color: #959595;
      }
    }

    .last {
      position: relative;
      bottom: 15px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #00e4ac;

      &::after {
        content: 'Last 10 days';
        position: absolute;
        top: 35px;
        right: 0;
        font-family: 'Inter';
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        white-space: pre;
        color: #959595;
      }
    }
    .percentageLast {
      &::before {
        content: '%';
        position: absolute;
        left: 100%;
        top: 2px;
        font-size: 12px;
      }
    }

    .zero {
      top: 0;
      color: #c1c5d6;
    }
  }

  .noData {
    position: absolute;
    left: 20px;
    top: 73px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 36px;
    color: #c1c5d6;
  }
}

@media (max-width: 1024px) {
  .Stat {
    width: 100%;
  }
}
