.Button {
  height: 56px;
  padding-left: 5em;
  padding-right: 5em;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  line-height: 24px;
  color: #242833;
  background-color: #00e4ac;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  &:disabled {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #c1c5d6;
    background-color: transparent;
    border: 1px solid #c1c5d6;
    cursor: auto;
  }

  &.setWidth {
    padding-left: unset;
    padding-right: unset;
  }

  &.modal {
    border: 1.5px solid white;
    color: white;
    background-color: transparent;

    &:hover {
      color: black;
      border: none;
    }
  }

  &.print {
    background-color: #f2f4f8;
    color: #c1c5d6;
  }

  &.black {
    background-color: #242833;
    color: white;

    &:hover {
      &:not(:disabled) {
        color: white;
        background-color: #c1c5d6;
        border: 1px solid #c1c5d6;
      }
    }
  }

  &.secondary {
    color: #c1c5d6;
    background-color: #f2f4f8;
    border: none;

    &:disabled {
      color: #c1c5d6;
      border: 1px solid #c1c5d6;
      cursor: auto;
    }

    &:hover {
      &:not(:disabled) {
        color: white;
        background-color: #c1c5d6;
        border: 1px solid #c1c5d6;
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: black;
        color: white;
      }
    }
  }

  &.delete {
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #242833;
    background-color: #c1c5d6;
    border: none;

    &:hover {
      background-color: #c1c5d6 !important;
      border: none !important;
      &:not(:disabled) {
        color: white;
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: black !important;
        color: white !important;
      }
    }
  }

  &.report {
    background-color: white;

    &:disabled {
      background-color: rgba(242, 244, 248, 1);
      border: 1px solid #c1c5d6;
      cursor: auto;

      &:hover {
        background-color: #c1c5d6;
      }

      img {
        filter: brightness(0%);
      }
    }

    &:hover {
      &:not(:disabled) {
        background-color: rgba(242, 244, 248, 1);
        border: 1px solid #c1c5d6;

        img {
          filter: brightness(0%);
        }
      }
    }

    &:active {
      &:not(:disabled) {
        background-color: #c1c5d6;
      }
    }
  }

  &:hover {
    &:not(:disabled) {
      color: #00e4ac;
      background-color: white;
      border: 1.5px solid #00e4ac;
    }
  }

  &:active {
    &:not(:disabled) {
      background-color: black;
      color: #ffffff;
    }
  }
}

/* @media (max-width: 1024px) {
  .Button {
    border-radius: 8px;
  }
} */
