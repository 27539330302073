.DashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;

  h1 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 22px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6722d2;
  }

  .dropdowns {
    display: flex;
    justify-content: flex-end;
    gap: 7px;
    flex: 1;
    padding-left: 80px;

    .dropdownContainer {
      max-width: 175px;
      flex: 1;
    }
  }
}

@media (max-width: 1024px) {
  .DashboardHeader {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    h1 {
      margin-bottom: 20px;
      font-size: 17px;
      line-height: 20px;
    }

    .dropdowns {
      gap: 15px;
      padding-left: 0;

      .dropdownContainer {
        width: 143px;
      }
    }
  }
}
