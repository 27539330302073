.SocialLink {
  display: flex;
  height: 100px;

  background-color: white;
  border: 1px solid #f2f4f8;
  border-radius: 16px;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);

  .handleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-right: 1px solid #f2f4f8;

    .handle {
      width: 10px;
      height: 16px;
      background-image: url(../../../../../../assets/images/dashboard/handle.svg);
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      h3 {
        margin: 0;
        font-family: Gilroy-SemiBold;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
      }

      .visibilityToggle {
        display: flex;
        align-items: center;
        gap: 4px;

        &.disabled {
          pointer-events: none;
        }

        .label {
          font-family: Gilroy-Regular;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .linkContainer {
      display: flex;
      align-items: center;
      gap: 13px;

      .link {
        flex: 1;
        height: 40px;
        padding-left: 14px;
        padding-right: 14px;
        font-family: Gilroy-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: #f2f4f8;
        border-radius: 12px;
        border: none;

        &.invalid {
          color: #ff2d55;
        }

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #c1c5d6;
        }
      }
    }
  }
}
