.AuthCarousel {
  height: 100vh;
  padding: 28px;

  &.noAnimation {
    .background {
      .carouselSlideContainer {
        transition: none !important;
      }
    }
  }

  .background {
    position: relative;
    width: 660px;
    height: 100%;
    background-image: url(../../../assets/images/carousel/top-left-shape.svg),
      url(../../../assets/images/carousel/top-right-shape.svg),
      url(../../../assets/images/carousel/bottom-left-shape.svg),
      url(../../../assets/images/carousel/bottom-right-shape.svg);
    background-position: top left, top right, left bottom 91px, bottom right;
    background-repeat: no-repeat;
    background-color: #f2f4f8;
    border-radius: 16px;
    overflow: hidden;

    .carouselSlideContainer {
      position: absolute;
      top: 69px;
      bottom: 72px;
      display: flex;
      gap: 65px;
      transition: left ease-in-out 0.3s;
    }

    .dots {
      position: absolute;
      left: 50%;
      bottom: 32px;
      transform: translateX(-50%);
      display: flex;
      gap: 20px;
    }
  }
}
