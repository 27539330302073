.tab {
  width: 100%;
  max-width: 1067px;
  // display: flex;

  .jobs {
    display: grid;
    grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
    gap: 15px;
    width: 100%;
    max-width: 1067px;
  }
}
