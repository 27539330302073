.NavButton {
  position: relative;
  width: 100%;

  &:hover {
    &:not(.active) {
      a {
        span {
          visibility: visible;
        }
      }
    }
  }

  &.active {
    a {
      span {
        visibility: visible;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      margin-bottom: 5px;
    }

    span {
      font-family: 'Gilroy-Bold';
      font-size: 12px;
      line-height: 20px;
      background: linear-gradient(
        242deg,
        #00e4ac 8.42%,
        #10c5e9 51.73%,
        #9240dc 94.19%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      visibility: hidden;
    }
  }
}
