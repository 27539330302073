.Dropdown {
  position: absolute;
  z-index: 3;
  top: calc(100% + 5px);
  left: 50%;
  height: 241px;
  width: 228px;
  background: #ffffff;
  box-shadow: 0px 4px 4px -6px rgba(0, 0, 0, 0.25),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  border-radius: 16px;
  transform: translateX(-50%);

  .header {
    height: 45px;
    padding: 16px;

    .online {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 24px;

      .headerTitle {
        margin-left: 6px;
        font-family: 'Gilroy-SemiBold';
        color: #c1c5d6;
      }
    }

    .tabs {
      display: flex;
      gap: 8px;
      align-items: center;

      .tab {
        display: flex;
        gap: 6px;
        align-items: center;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 150%;
        color: #c1c5d6;
        cursor: pointer;

        &.activeTab {
          color: #242833;
        }

        .tabIcon {
          width: 18px;
          height: 18px;
          background-size: contain;
          background-repeat: no-repeat;

          &.ThumbUp {
            width: 15px;
            height: 15px;
            background: url('../../../../assets/images/dashboard/discover/emojis/thumbs-up-active.svg');

            &.disabledIcon {
              background: url('../../../../assets/images/dashboard/discover/emojis/thumbs-up-disable.svg');
            }
          }

          &.Love {
            width: 16px;
            height: 16px;
            background: url('../../../../assets/images/dashboard/discover/emojis/heart-active.svg');

            &.disabledIcon {
              background: url('../../../../assets/images/dashboard/discover/emojis/heart-disable.svg');
            }
          }

          &.Celebrate {
            width: 19px;
            height: 19px;
            background: url('../../../../assets/images/dashboard/discover/emojis/celebrate-active.svg');

            &.disabledIcon {
              background: url('../../../../assets/images/dashboard/discover/emojis/celebrate-disable.svg');
            }
          }

          &.Views {
            background: url('../../../../assets/images/dashboard/discover/emojis/views-active.svg');
          }

          &.Comments {
            background-image: url('../../../../assets/images/dashboard/discover/commentActive.svg');
          }

          &.Angry {
            background-image: url('../../../../assets/images/dashboard/discover/emojis/angry.png');
          }

          &.Laugh {
            background-image: url('../../../../assets/images/dashboard/discover/emojis/laugh.png');
          }

          &.Suprised {
            background-image: url('../../../../assets/images/dashboard/discover/emojis/surprised.png');
          }

          &.Sad {
            background-image: url('../../../../assets/images/dashboard/discover/emojis/sad.png');
          }
        }
      }
    }
  }

  .content {
    position: relative;
    padding: 5px 3px 15px 16px;
    scrollbar-width: thin;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 17px;
      width: calc(100% - 34px);
      height: 1px;
      background-color: #c1c5d6;
    }
  }
}
