.DashboardPage {
  display: flex;
  height: 100vh;
  max-height: 100vh;

  .container {
    flex: 1;
    background-color: #f2f4f8;
  }
}

@media (max-width: 1024px) {
  .DashboardPage {
    overflow-y: auto;
    height: 100dvh;
    max-height: 100dvh;

    .container {
      width: 100%;
      flex: unset;
    }
  }
}
