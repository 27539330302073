.RequestNewPasswordPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .container {
    position: relative;
    top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 470px;
    padding-left: 35px;
    padding-right: 35px;
    max-height: calc(100vh - 104px);
    overflow-y: auto;

    h1 {
      margin-bottom: 126px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      color: #242833;
    }

    form {
      width: 100%;
      margin-bottom: 13px;

      .inputContainer {
        margin-bottom: 181px;
      }
    }

    .helper {
      width: 380px;
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #c1c5d6;
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .RequestNewPasswordPage {
    .container {
      top: 0;
      padding-bottom: 40px;

      h1 {
        margin-bottom: 50px;
      }

      form {
        .inputContainer {
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .RequestNewPasswordPage {
    .container {
      top: 0;
      padding-bottom: 40px;

      h1 {
        margin-bottom: 50px;
      }

      form {
        .inputContainer {
          margin-bottom: 50px;
        }
      }
    }
  }
}
