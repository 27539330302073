.candidateCard {
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 1067px;
  padding: 15px;
  height: max-content;
  background: white;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: -1;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: white;
      border-radius: var(--borderRadius);
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    .candidate {
      display: flex;
      align-items: center;
      gap: 15px;

      .photo {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        line-height: 20px;
        color: white;
        text-transform: uppercase;
        background: none;
        border-radius: 50%;
        object-fit: cover;
      }

      .noPhoto {
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
      }

      .container {
        display: flex;
        flex-direction: column;

        .name {
          font-family: 'Gilroy-Bold';
          font-size: 14px;
          line-height: 20px;
          color: #3c3c43;
        }

        .location {
          font-family: 'Gilroy-Regular';
          line-height: 20px;
          color: #242833;
        }
      }
    }

    .menu {
      position: relative;
      display: flex;
      align-items: center;

      .fav {
        background-image: url(../../../../../assets/images/dashboard/favorites/fav.svg);
        cursor: pointer;
      }

      .status {
        margin: 0 14px;
      }
      .isInvited {
        button {
          background: #c1c5d6;
          color: white;
        }
      }

      .note {
        position: absolute;
        top: 40px;
        right: 7px;
        width: 16px;
        height: 20px;
        background: url('../../../../../assets/images/dashboard/favorites/noteIcon.svg');
        cursor: pointer;

        &:hover ~ div {
          opacity: 1;
          visibility: visible;
        }
      }

      .noteBox {
        position: absolute;
        top: 65px;
        right: 15px;
        z-index: 1;
        width: 234px;
        word-wrap: break-word;
        min-height: 144px;
        height: max-content;
        opacity: 0;
        visibility: hidden;
        padding: 15px;
        background: white;
        box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
        border-radius: 16px;
        transition: opacity 0.3s;

        &::before {
          content: '';
          position: absolute;
          right: -5px;
          top: -16px;
          z-index: -1;
          border-top: 40px solid transparent;
          border-bottom: 40px solid transparent;
          border-left: 40px solid white;
          transform: rotate(45deg);
          border-radius: 42% 0 0 0;
        }
      }
    }
  }

  .jobs {
    .title {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 9px;
      font-family: 'Gilroy-SemiBold';
      color: #242833;
    }

    .jobsLost {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > div {
        width: 100%;
      }
    }
  }
}

.active {
  position: relative;
  z-index: 1;
  background: linear-gradient(
    228.41deg,
    #00e4ac 0%,
    #10c5e9 53.88%,
    #9240dc 106.71%
  );

  &::after {
    content: '';
    position: absolute;
    background: white;
    z-index: -1;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    border-radius: 8px;
    height: calc(100% - 4px);
  }
}
