.CandidatesList {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  &.tableView {
    &.fullScreen {
      .allCandidatesContainer {
        height: calc(100vh - 80px - 22px - 78px - 16px - 20px);
      }
    }

    .allCandidatesContainer {
      height: calc(100vh - 80px - 22px - 78px - 16px - 273px - 38px - 20px);
      overflow: hidden;

      .innerContainer {
        min-height: unset;
      }
    }
  }

  .headerContainer {
    margin-bottom: 23px;
  }

  .recentylViewedContainer {
    margin-bottom: 38px;
  }

  .allCandidatesContainer {
    display: flex;
    flex-direction: column;

    padding-top: 23px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    overflow-y: auto;
    scrollbar-color: #00e4ac transparent;
    scrollbar-width: thin;
  }

  &.fullScreen {
    .allCandidatesContainer {
      max-height: calc(100vh - 80px - 22px - 78px - 16px - 20px);
    }
  }
}

@media (max-width: 1024px) {
  .CandidatesList {
    height: unset;
    max-height: unset;

    &.tableView {
      &.fullScreen {
        .allCandidatesContainer {
          height: unset;
        }
      }

      .allCandidatesContainer {
        height: unset;
        overflow: auto;

        .innerContainer {
          width: 100%;
          overflow: auto;
        }
      }
    }

    &.fullScreen {
      .allCandidatesContainer {
        max-height: unset;
      }
    }
  }
}
