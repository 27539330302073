.Stat {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 150px;
  padding-top: 24px;
  padding-left: 31px;
  padding-right: 31px;
  padding-bottom: 10px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  &.zero {
    &::after {
      background-color: #c1c5d6;
    }

    .dynamic {
      color: #c1c5d6;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.12363 2.36312L4.67715 1.85704L3.665 0.75L3.11148 1.25608L0.923977 3.25608C0.768572 3.39816 0.680054 3.59903 0.680054 3.8096C0.680054 4.02017 0.768572 4.22104 0.923977 4.36312L3.11148 6.36312L3.665 6.8692L4.67715 5.76215L4.12363 5.25608L3.36186 4.5596H3.68005H4.43005H8.43005H9.18005H9.49825L8.73648 5.25608L8.18296 5.76215L9.19511 6.8692L9.74863 6.36312L11.9361 4.36312C12.0915 4.22104 12.1801 4.02017 12.1801 3.8096C12.1801 3.59903 12.0915 3.39816 11.9361 3.25608L9.74863 1.25608L9.19511 0.75L8.18296 1.85704L8.73648 2.36312L9.49825 3.0596H9.18005H8.43005H4.43005H3.68005H3.36186L4.12363 2.36312Z' fill='%23C1C5D6'/%3E%3C/svg%3E%0A");
    }
  }

  &.negative {
    &::after {
      background-color: #ffbf00;
    }

    .dynamic {
      color: #ffbf00;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0499 6.89062H9.29578V8.89062H13.2958H14.2958V7.89062V3.89062H12.2958V5.30808L8.99802 2.01032L8.29091 1.30321L7.5838 2.01032L5.47457 4.11955L2.09427 0.73924L0.680054 2.15345L4.76746 6.24087L5.47456 6.94798L6.18167 6.24088L8.29091 4.13164L11.0499 6.89062Z' fill='%23FFBF00'/%3E%3C/svg%3E%0A");
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 6px;
    height: 50px;
    background-color: #00e4ac;
    border-radius: 0px 100px 100px 0px;
  }

  h3 {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 20px;
    color: #c1c5d6;
    white-space: nowrap;
  }

  .value {
    font-family: 'Gilroy-Bold';
    font-size: 42px;
    line-height: 43px;
    color: #232426;
  }

  .noDataToCompare {
    font-family: 'Gilroy-Medium';
    color: #c1c5d6;
    font-size: 12px;
  }

  .dynamic {
    padding-left: 20px;
    font-family: 'Gilroy-Medium';
    font-size: 18px;
    line-height: 20px;
    color: #00e4ac;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.7545 2.21094H9.00037V0.210938H13.0004H14.0004V1.21094V5.21094H12.0004V3.79349L8.70261 7.09125L7.9955 7.79835L7.28839 7.09125L5.17916 4.98201L1.79886 8.36232L0.384644 6.94811L4.47205 2.86069L5.17915 2.15358L5.88626 2.86069L7.9955 4.96993L10.7545 2.21094Z' fill='%2300E4AC'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left center;
  }

  .period {
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 17px;
    color: #c1c5d6;
  }
}

@media (max-width: 1024px) {
  .Stat {
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
