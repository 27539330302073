.TopMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 268px;

  .addOptionsContainer {
    position: relative;
    z-index: 100;
  }

  i {
    display: block;
    font-size: 0;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;

    &.notificationIcon {
      &.hasUnreadNotifications {
        position: relative;

        &.active {
          &::after {
            top: 7px;
            right: 7px;
            width: 8px;
            height: 8px;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }

    &.chatIcon {
      &.hasUnreadChatMessages {
        position: relative;

        &.active {
          &::after {
            top: 7px;
            right: 5px;
            width: 8px;
            height: 8px;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }

    &.create {
      background-image: url(../../../../assets/images/dashboard/header/create.svg);
    }
  }
}

@media (max-width: 1024px) {
  .TopMenu {
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
}
