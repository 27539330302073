.DayMode {
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 20px;
  color: #242833;
  background-color: white;
  border-radius: 16px;

  .date {
    display: flex;
    align-items: center;
    gap: 15px;

    .day {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 20px;
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      box-shadow: 0px 1.5px 1px rgba(0, 31, 112, 0.25),
        0px 3px 2px rgba(0, 71, 255, 0.2);
    }

    .today {
      background: #6722d2;
      box-shadow: none;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

@media (max-width: 1047px) {
  .DayMode {
    min-width: 1024px;
  }
}
