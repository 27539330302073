.NumberInput {
  position: relative;

  &.withBackground {
    .inputContainer {
      input {
        width: 43px;
        height: 30px;
        color: #f2f4f8;
        background-color: #c1c5d6;
        border-radius: 16px;
      }
    }
  }

  .label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    white-space: nowrap;
    color: #242833;

    .note {
      margin-left: 5px;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 24px;
      color: #c1c5d6;
      white-space: nowrap;
    }
  }

  .inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 139px;
    height: 50px;
    background-color: #f2f4f8;
    border-radius: 16px;

    input {
      width: 50px;
      text-align: center;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }

    .red {
      color: #ff2d55;
    }

    .amber {
      color: #ffbf00;
    }

    i {
      user-select: none;
      cursor: pointer;

      &.minusButton {
        position: absolute;
        left: 23px;
      }

      &.plusButton {
        position: absolute;
        right: 23px;
      }
    }

    .error {
      position: absolute;
      bottom: -24px;
      left: 50%;
      margin-top: 6px;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 18px;
      color: #ff2d55;
      transform: translateX(-50%);
      white-space: nowrap;

      &.warning {
        color: #ffbf00;
      }
    }
  }
}
