.dialog {
  max-width: 785px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  height: 464px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
  0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.ShareJobModal {
  border-radius: 20px;

  .content {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    padding-bottom: 28px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      h1 {
        margin: 0;
        font-family: 'Gilroy-Bold';
        font-size: 40px;
        line-height: 40px;
        color: #242833;
      }

      .closeIcon {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 0;
        background-image: url(../../assets/images/modals/close.svg);
        cursor: pointer;
      }
    }

    .container {
      display: flex;
      gap: 50px;
      margin-bottom: 21px;

      .col {
        display: flex;
        flex-direction: column;
        flex: 1;

        .share {
          .selectContainer {
            margin-bottom: 26px;
          }
        }   
      }
    }

    footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
