.UsersOnline {
  position: relative;
  padding: 20px 21px 20px 20px;
  height: 80px;
  min-height: 80px;
  background-color: white;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .onlineBox {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 13px;
      width: 29px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gilroy-Bold';
      font-size: 8px;
      line-height: 9px;
      color: #f2f4f8;
      background: #ff2d55;
      border-radius: 0px 7px 7px 0px;
    }

    .flexContainer {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .container {
      position: relative;
      width: 44px;
      height: 44px;

      .totalNumberAtTime {
        position: relative;
        z-index: 1;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 50%;
        border: 2px solid white;
      }
      &::after {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        width: 46px;
        height: 46px;
        background: linear-gradient(
          228.41deg,
          #00e4ac 0%,
          #10c5e9 53.88%,
          #9240dc 106.71%
        );
        border-radius: 50%;
      }
    }

    .users {
      display: flex;

      .user {
        cursor: pointer;

        &:not(&:first-child) {
          margin-left: -5px;
        }
        &:hover .userName {
          visibility: visible;
        }

        &:hover img, &:hover .noPhoto {
          border-color: #10c5e9;
        }

        img {
          position: relative;
          z-index: 1;
          width: 35px;
          height: 35px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid #f2f4f8;
        }
    
        .noPhoto {
          position: relative;
          z-index: 1;
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Gilroy-Bold';
          background: linear-gradient(
            228.41deg,
            #00e4ac 0%,
            #10c5e9 53.88%,
            #9240dc 106.71%
          );
          font-size: 18px;
          color: white;
          border-radius: 50%;
          border: 1px solid #f2f4f8;
        }

        .userName {
          position: absolute;
          z-index: 2;
          left: 75px;
          top: 69px;
          padding: 12px 18px 13px 16px;
          height: 36px;
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 10px;
          white-space: nowrap;
          background: #ffffff;
          box-shadow: 0px 4px 60px rgba(62, 63, 99, 0.1);
          border-radius: 8px;
          visibility: hidden;
        }
      }
    }

    .others {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 19px;
      color: #242833;
      cursor: pointer;
    }
  }
}
