.AuthHeader {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 29px;
  .logo {
    position: relative;
    img {
      width: 78px;
      height: 23px;
      margin-bottom: 13px;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        &:first-child {
          font-family: 'Gilroy-Regular';
          font-size: 10px;
          line-height: 150%;
          display: flex;
          align-items: center;
          text-align: center;
          color: #c1c5d6;
        }

        &:last-child {
          font-family: 'Gilroy-Bold';
        }
      }
    }

    .backButtonContainer {
      position: absolute;
      top: 0;
      left: -28px;
      .backButton {
        display: block;
        width: 9px;
        height: 16px;
        font-size: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='17' viewBox='0 0 10 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.19584 2.34969C9.60139 1.94414 9.60139 1.28663 9.19584 0.881083C8.7903 0.475538 8.13278 0.475538 7.72724 0.881083L0.804158 7.80416C0.398614 8.2097 0.398614 8.86722 0.804158 9.27277L7.72724 16.1958C8.13278 16.6014 8.7903 16.6014 9.19584 16.1958C9.60139 15.7903 9.60139 15.1328 9.19584 14.7272L3.00707 8.53846L9.19584 2.34969Z' fill='%23242833'/%3E%3C/svg%3E%0A");
        cursor: pointer;
      }
    }
  }
}
