.Addressees {
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: center;
  gap: 54px;

  .title {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 16px;
  }

  .addresseesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    min-height: 50px;
    padding: 7px;
    border: 1px solid #c1c5d6;
    border-radius: 7px;

    .addMoreButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      padding: 0;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 16px;
      color: #0fc7e6;
      background-color: transparent;
      border: none;
    }
  }
}

@media (max-width: 1024px) {
  .Addressees {
    gap: 15px;
  }
}
