.tab {
  width: 1067px;
  max-width: 1067px;
  display: flex;

  .events {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    gap: 15px;
  }
}
