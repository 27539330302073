.ViewSwitch {
  display: flex;
  width: 79px;
  height: 48px;
  border: 1px solid white;
  backdrop-filter: blur(10.8731px);
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  div {
    flex: 1;
    height: 100%;
    background: #f2f4f8;
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      background-color: #725ee8;
    }

    &.list {
      background-image: url("data:image/svg+xml,%3Csvg width='23' height='27' viewBox='0 0 23 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.19971 19.9805H20.2247' stroke='%23C1C5D6' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.57471 19.9805H3.58351' stroke='%23C1C5D6' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.19971 13.3203H20.2247' stroke='%23C1C5D6' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.57471 13.3203H3.58351' stroke='%23C1C5D6' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.19971 6.66016H20.2247' stroke='%23C1C5D6' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.57471 6.66016H3.58351' stroke='%23C1C5D6' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='27' viewBox='0 0 23 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.19968 19.9805H20.2246' stroke='white' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.57477 19.9805H3.58357' stroke='white' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.19968 13.3203H20.2246' stroke='white' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.57477 13.3203H3.58357' stroke='white' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.19968 6.66016H20.2246' stroke='white' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.57477 6.66016H3.58357' stroke='white' stroke-width='1.85' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }

    &.grid {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7997 13.4414H13.3997C12.5161 13.4414 11.7997 14.1578 11.7997 15.0414V18.5614C11.7997 19.4451 12.5161 20.1614 13.3997 20.1614H15.7997C16.6834 20.1614 17.3997 19.4451 17.3997 18.5614V15.0414C17.3997 14.1578 16.6834 13.4414 15.7997 13.4414Z' fill='%23C1C5D6' stroke='%23C1C5D6' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.9997 13.4414H4.59969C3.71604 13.4414 2.99969 14.1578 2.99969 15.0414V18.5614C2.99969 19.4451 3.71604 20.1614 4.59969 20.1614H6.99969C7.88335 20.1614 8.59969 19.4451 8.59969 18.5614V15.0414C8.59969 14.1578 7.88335 13.4414 6.9997 13.4414Z' fill='%23C1C5D6' stroke='%23C1C5D6' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.7997 2.87891H13.3997C12.5161 2.87891 11.7997 3.59525 11.7997 4.47891V7.99891C11.7997 8.88256 12.5161 9.59891 13.3997 9.59891H15.7997C16.6834 9.59891 17.3997 8.88256 17.3997 7.99891V4.47891C17.3997 3.59525 16.6834 2.87891 15.7997 2.87891Z' fill='%23C1C5D6' stroke='%23C1C5D6' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.9997 2.87891H4.59969C3.71604 2.87891 2.99969 3.59525 2.99969 4.47891V7.99891C2.99969 8.88256 3.71604 9.59891 4.59969 9.59891H6.99969C7.88335 9.59891 8.59969 8.88256 8.59969 7.99891V4.47891C8.59969 3.59525 7.88335 2.87891 6.9997 2.87891Z' fill='%23C1C5D6' stroke='%23C1C5D6' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7997 13.4414H13.3997C12.516 13.4414 11.7997 14.1578 11.7997 15.0414V18.5614C11.7997 19.4451 12.516 20.1614 13.3997 20.1614H15.7997C16.6833 20.1614 17.3997 19.4451 17.3997 18.5614V15.0414C17.3997 14.1578 16.6833 13.4414 15.7997 13.4414Z' fill='white' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.99976 13.4414H4.59976C3.7161 13.4414 2.99976 14.1578 2.99976 15.0414V18.5614C2.99976 19.4451 3.7161 20.1614 4.59975 20.1614H6.99975C7.88341 20.1614 8.59976 19.4451 8.59976 18.5614V15.0414C8.59976 14.1578 7.88341 13.4414 6.99976 13.4414Z' fill='white' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.7997 2.87891H13.3997C12.516 2.87891 11.7997 3.59525 11.7997 4.47891V7.99891C11.7997 8.88256 12.516 9.59891 13.3997 9.59891H15.7997C16.6833 9.59891 17.3997 8.88256 17.3997 7.99891V4.47891C17.3997 3.59525 16.6833 2.87891 15.7997 2.87891Z' fill='white' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.99976 2.87891H4.59976C3.7161 2.87891 2.99976 3.59525 2.99976 4.47891V7.99891C2.99976 8.88256 3.7161 9.59891 4.59975 9.59891H6.99975C7.88341 9.59891 8.59976 8.88256 8.59976 7.99891V4.47891C8.59976 3.59525 7.88341 2.87891 6.99976 2.87891Z' fill='white' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
