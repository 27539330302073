.LinkButton {
  width: 40px;
  height: 40px;
  cursor: pointer;

  &.disabled {
    cursor: auto;
  }
}

.ActiveButton {
  position: relative;
  margin-left: -5px;
  margin-right: -5px;

  .outer {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 2px solid transparent;
    background: linear-gradient(
        223deg,
        #00e4ac 20.85%,
        #10c5e9 65.29%,
        #9240dc 100%
      )
      border-box;
    -webkit-mask: linear-gradient(white 0 0) padding-box,
      linear-gradient(white 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .inner {
    position: absolute;
    z-index: 122;
    left: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
  }
}
