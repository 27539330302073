.Recipient {
  display: flex;
  align-items: center;
  gap: 20px;

  &.rounded {
    img {
      border-radius: 50%;
    }

    .noAvatar {
      border-radius: 50%;
    }
  }

  .name {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 150%;
    color: #242833;
  }

  img {
    border-radius: 14px;
  }

  .noAvatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    border-radius: 14px;

    span {
      font-family: 'Gilroy-SemiBold';
      font-size: 46px;
      line-height: 46px;
      color: white;
    }
  }
}

@media (max-width: 1024px) {
  .Recipient {
    gap: 10px;

    .name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      min-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 7px;
    }

    .noAvatar {
      min-width: 60px;
      max-width: 60px;
      height: 60px;
      border-radius: 7px;
      span {
        font-size: 36px;
        line-height: 36px;
      }
    }
  }
}
