.RadioButton {
  display: flex;
  gap: 10px;
  cursor: pointer;

  &.bigAndBold {
    .label {
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }

    .button {
      width: 24px;
      height: 24px;
    }

    &.selected {
      .button {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='14.25' stroke='%2300E4AC' stroke-width='1.5'/%3E%3Ccircle cx='15' cy='15' r='10' fill='%2300E4AC'/%3E%3C/svg%3E%0A");
        background-size: 24px;
      }
    }
  }

  &.selected {
    .button {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7' y='7' width='10' height='10' rx='5' fill='%2300E4AC' stroke='%2300E4AC' stroke-width='2'/%3E%3Crect x='1' y='1' width='22' height='22' rx='11' stroke='%2300E4AC' stroke-width='2'/%3E%3C/svg%3E%0A");
      border: none;
    }

    .label {
      font-family: Gilroy-SemiBold;
      color: #242833 !important;
    }
  }

  .button {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border: 2px solid #c1c5d6;
    border-radius: 24px;
  }

  .label {
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    line-height: 24px;
    color: #c1c5d6;
  }
}

@media (max-width: 1024px) {
  .RadioButton {
    &.bigAndBold {
      .label {
      }

      .button {
      }

      &.selected {
        .button {
        }
      }
    }

    &.selected {
      .button {
      }

      .label {
      }
    }

    .button {
    }

    .label {
      white-space: nowrap;
    }
  }
}
