.SetNewPassword {
  position: relative;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 40px;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 15px;
  }

  .container {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;

    .column {
      flex: 1;
      flex-direction: column;
      display: flex;
      gap: 15px;
    }
  }

  .validations {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .text {
        font-family: 'Gilroy-SemiBold';
        font-size: 16px;
        line-height: 20px;
        color: #242833;
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 0;
        padding: 0;
        padding-left: 20px;
        list-style: none;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: #242833;

        .rule {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: calc(100% + 10px);
            width: 3px;
            height: 3px;
            background: #242833;
            border-radius: 50%;
            transform: translateY(-50%);
          }
        }

        .activeRule {
          color: #00e4ac;

          &::before {
            content: '\2714';
            right: calc(100% + 6px);
            width: auto;
            height: auto;
            background: transparent;
          }
        }
      }
    }
  }

  .buttons {
    position: absolute;
    bottom: 0;
    align-self: center;
    display: flex;
    gap: 30px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 32px;
  }
}

@media (max-width: 1024px) {
  .SetNewPassword {
    .title {
    }

    .container {
      flex-direction: column;
      gap: 30px;
      .column {
        gap: 30px;
      }
    }

    .validations {
      margin-bottom: 102px;
      .row {
        .text {
        }

        .list {
          .rule {
            &::before {
            }
          }

          .activeRule {
            &::before {
            }
          }
        }
      }
    }
    .buttons {
      position: static;
      flex-direction: column;
      gap: 15px;
      width: 100%;
    }
  }
}
