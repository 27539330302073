.AuthLayout {
  display: flex;
  max-width: 100%;
  .leftColumn {
    padding-left: 107px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
