.details {
  display: flex;
}

.information {
  width: 100%;
}

.application {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  gap: 40px;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 1px;
    background-color: #c1c5d6;
  }

  .applicationDateAndJobOwner {
    display: flex;
    gap: 40px;
  }

  .name {
    color: #c1c5d6;
  }
}

.title {
  margin: 7px 0 12px;
  font-family: 'Gilroy-Bold';
  font-size: 18px;
  color: #3c3c43;
}

.container {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.name {
  margin: 0;
  font-family: 'Gilroy-Regular';
  font-size: 14px;
  color: #707070;
}

.description {
  margin: 0;
  font-family: 'Gilroy-Bold';
  font-size: 16px;
  color: #3c3c43;
}

.positions {
  padding: 0 12px;
  width: 314px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f4f8;
  border-radius: 16px;

  .text {
    margin: 0;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
  }

  .amount {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-family: 'Gilroy-Bold';
    color: #ff2d55;
    background-color: white;
    border-radius: 50%;
  }

  .seeAll {
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #242833;
    cursor: pointer;
  }
}

.basicInfo {
  display: flex;
  flex-wrap: wrap;

  .container {
    flex: 0 0 33%;
    margin: 5px 0 24px;
  }
}

.hasExperience {
  margin: 0 0 20px;
  display: flex;
  justify-self: center;
  align-items: center;
  width: 100%;
  gap: 15%;

  .isEnabled {
    margin: 0;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 24px;

    color: #707070;
  }
}

.experience {
  height: 125px;
  overflow-y: scroll;

  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .exp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    &:last-child .container {
      margin-bottom: 0;
    }
  }

  .container {
    margin: 5px 0 20px;
  }

  .container:last-child {
    margin-right: 50px;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #00e4ac;
}

@media (max-width: 1024px) {
  .details {
    flex-direction: column;
  }

  .information {
  }

  .application {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #f2f4f8;

    &::after {
      display: none;
    }

    .name {
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 24px;
  }

  .applicationDateAndJobOwner {
    justify-content: space-between;
  }

  .container {
    gap: 10px;
  }

  .name {
    font-size: 14px;
    line-height: 20px;
  }

  .description {
  }

  .positions {
    width: 100%;
    height: 45px;
    justify-content: unset;

    .text {
      font-size: 14px;
      font-weight: 400;
    }

    .amount {
      width: 30px;
      height: 30px;
      margin-left: auto;
      margin-right: 10px;
    }

    .seeAll {
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 20px;
    }
  }

  .basicInfo {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    row-gap: 20px;

    .container {
      margin: 0;

      .name {
        color: #c1c5d6;
      }

      .description {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .hasExperience {
    display: flex;
    justify-content: space-between;
    grid-column: 1 / -1;
    margin-top: 20px;
    margin-bottom: 40px;

    .name {
      font-family: 'Gilroy-Bold';
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .experience {
    height: unset;
    margin-bottom: 20px;

    .exp {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      row-gap: 20px;
      &:last-child .container {
      }
    }

    .container {
    }

    .container:last-child {
    }
  }
}
