.ProfileSettings {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;

  &.noPaddings {
    .container {
      padding: 0;
    }
  }

  .title {
    margin: 0;
    margin-bottom: 65px;
    font-family: 'Gilroy-Bold';
    font-size: 20px;
    line-height: 25px;
    color: #242833;
  }

  .container {
    position: relative;
    padding-top: 55px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 27px;
    height: 728px;
    //  margin-top: 90px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 0px 16px 16px 16px;
  }
}

@media (max-width: 1024px) {
  .ProfileSettings {
    height: unset;
    padding-bottom: 64px;
    background-color: #f2f4f8;
    .container {
      height: unset;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top-right-radius: 0;
    }
  }
}
