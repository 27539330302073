.container {
  position: relative;

  .border {
    position: absolute;
    z-index: 0;
    left: -2px;
    top: -2px;
    display: none;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );
    border-radius: 8px;
    border-radius: 16px;
  }

  &:hover {
    .border {
      display: block;
    }
  }

  .CandidateGridCard {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    padding-top: 21px;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 27px;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    cursor: pointer;

    &.joined {
      background-color: #242833;

      .photoBlock {
        .name,
        .location {
          color: white;
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .buttons {
        display: flex;
        align-items: center;
        gap: 4px;

        .threeDots {
          width: 20px;
          height: 20px;
          background-image: url(../../../../../../assets/images/dashboard/candidates/three-dots.svg);
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
        }
      }
    }

    .photoBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
      }

      .defaultAvatarContainer {
        margin-bottom: 10px;
      }

      .name {
        min-height: 18px;
        margin-bottom: 4px;
        font-family: 'Gilroy-Bold';
        font-size: 18px;
        line-height: 18px;
        color: #3c3c43;
      }

      .location {
        min-height: 19px;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 19px;
        color: #b4b1b1;
      }
    }

    .candidateDetails {
      width: 100%;
      padding-top: 10px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 8px;
      background-color: #f2f4f8;
      border-radius: 8px;

      .jobsApplied {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
        .title {
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 24px;
          color: #242833;
        }

        .count {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 39px;
          height: 24px;
          font-family: 'Gilroy-Bold';
          font-size: 14px;
          line-height: 24px;
          color: #f2f4f8;
          background-color: #00e4ac;
          border-radius: 16px;
        }
      }
      .email,
      .phone {
        min-height: 20px;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        color: black;
      }
    }
  }
}
