.GoTopButton {
  position: fixed;
  z-index: 1000;
  bottom: 30px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    border: none;
    box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
  }
}
