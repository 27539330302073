.Contact {
  position: relative;
  display: flex;
  height: 69px;
  padding-left: 31px;
  padding-right: 31px;
  cursor: pointer;

  &.active {
    background-color: rgba(0, 228, 172, 0.1);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 5px;
      height: 69px;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
    }
  }

  .photoContainer {
    position: relative;
    height: 100%;
    padding-top: 8px;
    margin-right: 15px;

    .noAvatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 56px;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: white;
      background: linear-gradient(
        228.41deg,
        #00e4ac 0%,
        #10c5e9 53.88%,
        #9240dc 106.71%
      );
      border-radius: 50%;
    }

    &::after {
      position: absolute;
      top: 32px;
      left: calc(50% - 21px);
      z-index: 1;
      content: '';
      display: block;
      width: 42px;
      height: 35px;

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(6, 217, 195, 0.4) 100%
      );
      opacity: 0.4;
      filter: blur(14.2718px);
      border-radius: 11.4174px;
    }

    img {
      position: relative;
      width: 56px;
      min-width: 56px;
      height: 56px;
      min-height: 56px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 180px;
    height: 100%;
    padding-top: 13px;
    padding-bottom: 10px;
    overflow: hidden;

    h4 {
      margin: 0;
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      line-height: 19px;
      color: #242833;
    }

    .message {
      margin: 0;
      font-family: 'Gilroy-Medium';
      font-size: 16px;
      line-height: 19px;
      color: #b1b6ca;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 60px;
    height: 100%;
    flex: 1;
    padding-top: 12px;
    padding-bottom: 10px;

    .createdAt {
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      letter-spacing: 0.01em;
      color: #c1c5d6;
      white-space: nowrap;
      opacity: 0.8;
    }

    .unreadMessageCount {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-family: 'Gilroy-SemiBold';
      font-size: 14px;
      line-height: 16px;
      color: white;
      background-color: #ff2d55;
      border-radius: 50%;
    }
  }
}
