.Tabs {
  position: absolute;
  bottom: calc(100%);
  left: 0;
  display: flex;
  gap: 26px;

  .tab {
    width: 200px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Bold';
    font-size: 16px;
    line-height: 15px;
    color: #f2f4f8;
    background: #c1c5d6;
    border-radius: 16px 16px 0px 0px;
    cursor: pointer;
  }

  .activeTab {
    color: #242833;
    background-color: white;
    box-shadow: -4px -19px 21px rgba(0, 0, 0, 0.04);
  }
}

@media (max-width: 1024px) {
  .Tabs {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab {
      min-width: 160px;
    }
  }
}
