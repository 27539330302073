.BrandName {
  .inputContainer {
    input {
      display: block;
      width: 100%;
      height: 40px;
      padding: 0;
      padding-bottom: 5px;
      font-family: Gilroy-Bold;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      border: none;

      &::placeholder {
        color: #c1c5d6;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .savedName {
    display: flex;
    gap: 20px;
    align-items: center;

    span {
      position: relative;
      top: -2px;
      font-family: Gilroy-Bold;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      word-break: break-all;
    }

    .editButton {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}
