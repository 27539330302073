.LoginPage {
  display: flex;
  align-items: center;
  padding-left: 140px;
  padding-right: 140px;
  flex: 1;

  .container {
    position: relative;
    top: -23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h1 {
      position: relative;
      left: 70px;
      margin-bottom: 84px;
    }

    form {
      width: 400px;
      margin-bottom: 125px;
    }

    .buttonsContainer {
      width: 400px;
      margin-bottom: 13px;
    }

    .helper {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #c1c5d6;

      a {
        text-decoration: none;
      }

      span {
        color: #00e4ac;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1600px) {
  .LoginPage {
    .container {
      top: -23px;
      h1 {
        margin-bottom: 64px;
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .LoginPage {
    .container {
      h1 {
        margin-bottom: 44px;
      }
    }
  }
}

@media (max-height: 700px) {
  .LoginPage {
    .container {
      h1 {
        margin-bottom: 24px;
      }
      form {
        margin-bottom: 30px;
      }
    }
  }
}
