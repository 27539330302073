.Remote {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    justify-content: space-between;

    > div {
      width: 100%;
    }
  }

  &.calendar {
    .сontact {
      width: 367px;
      margin-right: 20px;
    }
  }

  .сontact {
    width: 350px;

    .selectedUsers {
      margin-top: 26px;
    }
  }

  .textareaContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
  }
}

@media (max-width: 1024px) {
  .Remote {
    &.calendar {
      gap: 24px;

      .row {
        flex-direction: column;
        &:first-child {
          > div {
            gap: 24px;
          }
        }

        &:last-child {
          label {
            white-space: nowrap;

            span {
              &:last-child {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .сontact {
        width: 100%;

        .selectedUsers {
          &.hasUsers {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
