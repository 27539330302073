.Table,
.toPrint {
  margin-top: 15px;
  // padding: 19px 17px 26px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  height: calc(100vh - 80px - 90px - 35px);
  width: calc(100vw - 30px - 228px - 100px - 24px);
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px -4px 60px 0px rgba(62, 63, 99, 0.1);
  border-radius: 16px;

  &.toPrint {
    width: 100%;
    height: 100%;
    background: none;
    box-shadow: none;

    h1 {
      margin: 0;
      flex-shrink: 0;
      font-family: 'Gilroy-Bold';
      font-size: 24px;
      line-height: 24px;
      color: #242833;
    }

    .tableContainer {
      overflow: hidden;

      .reportTable {
        tr {
          td,
          th {
            min-width: 40px;
          }
        }
      }

      .noData {
        width: max-content;
        margin-top: 40px;
      }
    }
  }

  .tableContainer {
    position: relative;
    height: 100%;
    overflow: auto;
    margin-right: 15px;

    &::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9px;
    }

    .reportTable {
      position: relative;
      display: flex;
      flex-direction: column;
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 18px;
      color: #242833;

      tr {
        height: 36px;
        display: flex;

        &:first-child th {
          border-top: 1px solid #c1c5d6;

          &:first-child {
            border-radius: 16px 0 0 0;
          }

          &:last-child {
            border-radius: 0 16px 0 0;
          }

          &:first-child:last-child {
            border-radius: 16px 16px 0 0;
          }
        }

        &:last-child td {
          &:first-child {
            border-radius: 0 0 0 16px;
          }

          &:last-child {
            border-radius: 0 0 16px 0;
          }

          &:first-child:last-child {
            border-radius: 0 0 16px 16px;
          }
        }
      }

      td,
      th {
        min-width: 234px;
        flex: 1 0;
        padding: 9px 8px;
        border-bottom: 1px solid #c1c5d6;
        border-right: 1px solid #c1c5d6;

        &:first-child {
          border-left: 1px solid #c1c5d6;
        }
      }
    }

    .noData {
      position: absolute;
      left: 50%;
      top: calc(50% - 21px);
      transform: translate(-50%, -50%);
      max-width: 481px;
      display: flex;
      flex-direction: column;
      gap: 0;
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #c1c5d6;

      h4 {
        margin: 0;
        font-family: Gilroy-Bold;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 83.333% */
        letter-spacing: 0.24px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Table {
    width: unset;
    min-width: unset;
    height: unset;

    &.hasNoData {
      height: 100%;
    }

    .tableContainer {
      overflow: unset;
      height: 100%;
    }
  }
}
