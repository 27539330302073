.NotificationItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    line-height: 20px;
    color: #242833;

    .description {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #c1c5d6;
    }
  }
}
