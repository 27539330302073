.Gallery {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 262px;

  .container {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 206px;

    .firstCol {
      flex: 0.46;
      // aspect-ratio: 338 / 206;
    }
    .secondCol {
      display: flex;
      flex-direction: column;
      gap: 13px;
      flex: 0.25;

      > div {
        &:first-child {
          height: 123px;
          flex: 1;
        }

        &:last-child {
          height: 70px;
        }
      }
    }
    .thirdCol {
      flex: 0.29;
    }
  }
}

@media (max-width: 1024px) {
  .Gallery {
    padding-right: 15px;
  }
}
