.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: -5px;
  bottom: -20px;
  z-index: -1;
  min-width: 1305px;
  opacity: 0;
  background-color: rgba(37, 40, 51, 0.1);
  backdrop-filter: blur(0.5px);
  overflow-y: scroll;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;
  cursor: default;
}

.blurActivated {
  opacity: 1;
  z-index: 4;
}

.containerPopup {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 0;
  z-index: 3;
  // padding: 36px 50px 50px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 826px;
  height: 100vh;
  min-height: 982px;
  background-color: white;
  box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12),
    0px 8px 22px -6px rgba(24, 39, 75, 0.12);
  visibility: hidden;
  transform: translateX(100%);
  transition: transform 0.3s linear, visibility 0.3s linear;
  scrollbar-color: #00e4ac transparent;
  scrollbar-width: thin;

  .closeIconContainer {
    position: absolute;
    top: 62px;
    left: -67px;
    width: 67px;
    height: 55px;
    display: none;
    align-items: center;
    padding-left: 13px;
    background-color: #6722d2;
    border-radius: 50px 0px 0px 50px;
    .closeIcon {
      display: block;
      width: 32px;
      height: 32px;
      font-size: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.5' width='32' height='32' rx='16' fill='%23C1C5D6'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.9144 15.0252L19.9196 11.02C20.2125 10.7271 20.6874 10.7271 20.9803 11.02C21.2732 11.3129 21.2732 11.7877 20.9803 12.0806L16.9751 16.0858L20.9803 20.091C21.2732 20.3839 21.2732 20.8588 20.9803 21.1517C20.6874 21.4446 20.2125 21.4446 19.9196 21.1517L15.9144 17.1465L11.9092 21.1517C11.6163 21.4446 11.1415 21.4446 10.8486 21.1517C10.5557 20.8588 10.5557 20.3839 10.8486 20.091L14.8538 16.0858L10.8486 12.0806C10.5557 11.7877 10.5557 11.3129 10.8486 11.02C11.1415 10.7271 11.6163 10.7271 11.9092 11.02L15.9144 15.0252Z' fill='%23F2F4F8'/%3E%3C/svg%3E%0A");
      cursor: pointer;
    }
  }
}

.visible {
  visibility: visible;
  transform: translateX(0);
}

.dialog {
  max-width: 815px;
}

.backdrop {
  background-color: transparent;
}

.modalContent {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 8px 22px -6px rgba(24, 39, 75, 0.12),
    0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.interview {
  height: 100%;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 44px;

    h1 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 28px;
      line-height: 30px;
      color: #242833;
    }

    .closeIcon {
      display: block;
      width: 32px;
      height: 32px;
      font-size: 0;
      background-image: url(../../../../../../assets/images/modals/close-dark.svg);
      cursor: pointer;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    flex: 1;
    pointer-events: all;

    .row {
      display: flex;
      gap: 15px;

      .onboardingModeSelector {
        display: flex;
        align-items: center;
        gap: 60px;

        h2 {
          margin: 0;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: #242833;
        }

        .radios {
          display: flex;
          gap: 40px;
        }
      }

      .headerCol {
        overflow: hidden;
      }

      .col,
      .headerCol {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-family: 'Gilroy-Regular';
        font-size: 16px;
        line-height: 24px;
        color: #c1c5d6;
        white-space: nowrap;

        .selectedUsers {
          margin-top: 26px;
        }

        .dropdownTitle {
          margin-bottom: 5px;
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          color: #242833;
        }

        .dropdowns {
          display: flex;
          justify-content: space-between;
        }

        .applicationInfo {
          h3 {
            margin: 0;
            font-family: 'Gilroy-Bold';
            font-size: 18px;
            line-height: 24px;
            color: #3c3c43;
          }

          .candidate {
            display: flex;
            gap: 15px;

            .view {
              width: 100px;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              background: linear-gradient(
                228.41deg,
                #00e4ac 0%,
                #10c5e9 53.88%,
                #9240dc 106.71%
              );
              border-radius: 16px;
              pointer-events: all;
              cursor: pointer;
            }
          }

          h2 {
            margin: 0;
            font-family: 'Gilroy-SemiBold';
            font-size: 35px;
            line-height: 36px;
            color: #242833;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .status {
            display: flex;
            gap: 11px;
            flex-direction: column;

            .statusText {
              margin-top: 6px;
              padding: 0 15px;
              width: max-content;
              height: 28px;
              display: flex;
              flex-shrink: 0;
              justify-content: center;
              align-items: center;
              font-family: 'Gilroy-Regular';
              font-size: 14px;
              line-height: 20px;
              color: white;
              background: #00e4ac;
              border-radius: 16px;
            }

            .statusDeclined {
              background-color: #ff2d55;
            }

            .changeRequest {
              background-color: #ffbf00;
            }

            .statusDate {
              font-family: 'Gilroy-SemiBold';
              font-size: 16px;
              line-height: 24px;
              color: #ffbf00;
            }
          }

          .jobId {
            font-family: 'Gilroy-Regular';
            font-size: 16px;
            line-height: 24px;
            color: #3c3c43;
          }
        }

        .linkButton {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 186px;
          height: 50px;
          margin-bottom: 41px;
          margin-left: auto;
          font-family: 'Gilroy-SemiBold';
          font-size: 16px;
          line-height: 24px;
          color: #242833;
          background-color: #f2f4f8;
          border: 1px solid #f2f4f8;
          border-radius: 16px;
        }
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 129px;
    gap: 10px;
    border-top: 1.5px solid #c1c5d6;
  }

  .isViewed {
    pointer-events: none;
  }
}

@media (max-width: 1024px) {
  .bottom {
    position: absolute;
    height: 19px;
    width: 100%;
    top: 1380px;
  }

  .containerPopup {
    top: 70px;
    left: 15px;
    right: 15px;
    width: calc(100vw - 30px);
    min-width: unset;
    height: unset;
    margin-bottom: 19px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 16px;
    transition: none;
  }

  .content {
    justify-content: unset;

    header {
      margin-bottom: 20px;
    }

    .container {
      gap: 30px;
      .row {
        flex-direction: column;
        gap: 30px;

        .dropdownContainer {
          width: calc(50% - 7.5px);
        }

        .col,
        .headerCol {
          .dropdowns {
            &.notification {
              gap: 13px;

              > div {
                &:first-child {
                  flex: 1;
                }

                &:last-child {
                  flex: 1;
                }
              }
            }
          }
        }
      }
    }

    footer {
      flex-direction: column;
      gap: 25px;
      height: 186px;

      button {
        width: 100% !important;
      }
    }
  }
}
