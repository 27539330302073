.Question {
  .header {
    margin-bottom: 18px;
    h4 {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 18px;
      line-height: 24px;
      color: #242833;
    }
  }

  .questionTitle {
    margin: 0;
    margin-bottom: 22px;
    padding-left: 18px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 18px;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 24px;
    color: #242833;
    background-color: #f2f4f8;
    border-radius: 8px;
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .Question {
    .header {
      margin-bottom: 15px;
      h4 {
        font-size: 16px;
      }
    }

    .questionTitle {
      margin-bottom: 20px;
    }

    .answers {
      gap: 15px;
    }
  }
}
