.ContactInfo {
  display: flex;
  justify-content: space-between;
  gap: 5px;

  & > div {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .messageToCandidate {
    display: block;

    .title {
      display: inline-block;
      margin-bottom: 4px;
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #242833;
    }

    .input {
      display: block;
      width: 100%;
      height: 159px;
      padding: 15px;
      font-family: Gilroy-SemiBold;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      background-color: #f2f4f8;
    }
  }

  .contacts {
    .title {
      margin-bottom: 16px;
      font-family: Gilroy-SemiBold;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .users {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      row-gap: 40px;

      .user {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &:hover .email {
          visibility: visible;
        }

        .photo {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Gilroy-Bold';
          font-size: 18px;
          line-height: 24px;
          color: white;
          background: linear-gradient(
            228.41deg,
            #00e4ac 0%,
            #10c5e9 53.88%,
            #9240dc 106.71%
          );
          border-radius: 14px;
        }

        .email {
          position: absolute;
          bottom: -35%;
          width: max-content;
          visibility: hidden;
        }
      }
    }
  }

  .input {
    width: 314px;
    height: max-content;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    background: #f2f4f8;
    border: none;
    outline: none;
    border-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .ContactInfo {
    flex-direction: column;
    gap: 15px;
  }
}
