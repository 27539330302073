.Slide {
  min-width: 531px;
  max-width: 531px;
  width: 531px;
  height: 100%;
  padding-top: 51px;
  padding-left: 35px;
  padding-right: 35px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(25px);
  border-radius: 16px;
  background-image: url(../../../../assets/images/carousel/image-1.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border: 1px solid #ffffff;

  h1 {
    font-family: 'Gilroy-SemiBold';
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    color: #242833;
  }
}
