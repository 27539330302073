.Checkbox {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  .button {
    width: 19px;
    height: 19px;
    background-color: #ececec;
    border-radius: 5px;
  }

  .label {
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  }

  &.selected {
    .button {
      background-color: white;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.01' fill-rule='evenodd' clip-rule='evenodd' d='M0 0V24H24V0H0Z' fill='%2350A1FF'/%3E%3Cmask id='mask0_159_1356' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.54497 5.73005C3 6.79961 3 8.19974 3 11V13C3 15.8003 3 17.2004 3.54497 18.27C4.02433 19.2108 4.78924 19.9757 5.73005 20.455C6.79961 21 8.19974 21 11 21H13C15.8003 21 17.2004 21 18.2699 20.455C19.2108 19.9757 19.9757 19.2108 20.455 18.27C21 17.2004 21 15.8003 21 13V11C21 8.19974 21 6.79961 20.455 5.73005C19.9757 4.78924 19.2108 4.02433 18.2699 3.54497C17.2004 3 15.8003 3 13 3H11C8.19974 3 6.79961 3 5.73005 3.54497C4.78924 4.02433 4.02433 4.78924 3.54497 5.73005ZM16.7179 8.80385C16.3334 8.40737 15.7003 8.39763 15.3039 8.7821L10.8438 13.107L9.19615 11.5094C8.79966 11.1249 8.16657 11.1346 7.7821 11.5311C7.39763 11.9276 7.40737 12.5607 7.80385 12.9452L10.1476 15.2179C10.5355 15.594 11.152 15.594 11.5399 15.2179L16.6961 10.2179C17.0926 9.83343 17.1024 9.20034 16.7179 8.80385Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_159_1356)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H23.976V24H0V0Z' fill='%2300E4AC'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center;
    }

    .label {
      font-weight: bold;
    }
  }
}
