.RequestAccessSuccessPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    max-height: calc(100vh - 104px);
    overflow-y: auto;

    h1 {
      width: 100%;
      margin-bottom: 122px;
      text-align: left;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      color: #242833;
    }

    p {
      height: 363px;
      margin: 0;
      margin-bottom: 99px;
      font-family: 'Gilroy-SemiBold';
      font-size: 35px;
      line-height: 36px;
      font-weight: 600;
      color: #242833;
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .RequestAccessSuccessPage {
    .container {
      top: 0;
      padding-bottom: 40px;
    }
  }
}
