.CalendarEventCard {
  position: relative;
  z-index: 0;
  width: 100%;
  min-height: 129px;
  height: 129px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 14px;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: -1;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: white;
    border-radius: 8px;
  }

  &:hover {
    background: linear-gradient(
      228.41deg,
      #00e4ac 0%,
      #10c5e9 53.88%,
      #9240dc 106.71%
    );

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: -1;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: white;
      border-radius: 16px;
    }
  }

  .iconBox {
    width: 80px;
    height: 80px;
    padding: 6px;
    border-radius: 16px;
    font-family: 'Gilroy-Bold';
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: white;

    .eventDate {
      width: 67px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      font-size: 32px;
      line-height: 38px;
      color: #242833;
      border-radius: 16px;
    }
  }

  .isPast {
    background: #c1c5d6;
  }

  .isFuture {
    background: #242833;
  }

  .isToday {
    background: #00e4ac;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    .date {
      font-family: 'Gilroy-Bold';
      font-size: 14px;
      color: #242833;
    }

    .time {
      font-family: 'Gilroy-Medium';
      font-size: 14px;
      color: #c1c5d6;
    }

    .title,
    .person {
      font-family: 'Gilroy-Regular';
      font-size: 14px;
      line-height: 20px;
      color: #242833;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .title {
      font-family: Gilroy-SemiBold;
    }
  }

  .favIcon {
    position: absolute;
    top: 11px;
    right: 12px;
    background-image: url(../../../../../assets/images/dashboard/favorites/fav.svg);
  }
}

.joining,
.onboarding {
  .info {
    .time {
      color: #242833;
    }
  }
}

.joining {
  background: #d2faff;

  &:hover {
    &::after {
      background: #d2faff;
    }
  }

  &::after {
    background: #d2faff;
  }
}

.onboarding {
  background: #cc9cff;

  &:hover {
    &::after {
      background: #cc9cff;
    }
  }

  &::after {
    background: #cc9cff;
  }
}

.selected {
  background: linear-gradient(
    228.41deg,
    #00e4ac 0%,
    #10c5e9 53.88%,
    #9240dc 106.71%
  );

  &::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: -1;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 16px;
  }
}
