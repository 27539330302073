.OtpSignInPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .container {
    position: relative;
    top: -35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //  justify-content: center;
    width: 400px;
    max-height: calc(100vh - 104px);
    overflow-y: auto;

    h1 {
      margin-bottom: 245px;
      font-family: 'Gilroy-Bold';
      font-size: 52px;
      line-height: 150%;
      color: #242833;
    }

    .inputs {
      margin-bottom: 44px;
      & > div {
        & > div {
          display: flex;
          justify-content: space-between !important;
          min-width: 400px;

          .input {
            width: 60px;
            height: 60px;
            margin: 0;
            padding-left: 0;
            padding-top: 0;
            font-family: 'Gilroy-Bold';
            font-size: 18px;
            line-height: 24px;
            background-color: #f2f4f8;
            border-radius: 16px;
            border: none;

            &::placeholder {
              color: #c1c5d6;
            }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .helper {
      margin-bottom: 67px;
      font-family: 'Gilroy-Bold';
      p {
        &:first-child {
          margin: 0;
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          font-weight: bold;
          color: #c1c5d6;

          span {
            color: #00e4ac;
            cursor: pointer;
          }
        }

        &:last-child {
          margin: 0;
          font-family: 'Gilroy-Regular';
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #c1c5d6;
        }
      }
    }
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .OtpSignInPage {
    .container {
      top: 0;
      padding-bottom: 40px;

      h1 {
        margin-bottom: 100px;
      }
    }
  }
}
