.Tabs {
  display: flex;
  gap: 26px;
  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 200px;
    width: 200px;
    height: 45px;
    background-color: #c1c5d6;
    border-radius: 16px 16px 0px 0px;
    cursor: pointer;

    &.active {
      background-color: white;
      box-shadow: 0px 4px 60px rgba(24, 39, 75, 0.1);

      span {
        color: #242833;
      }

      .count {
        background-color: #00e4ac;
      }
    }

    span {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
      line-height: 20px;
      color: #f2f4f8;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 28px;
      font-family: Gilroy-Bold;
      font-size: 14px;
      line-height: 20px;
      color: white;
      background-color: #c1c5d6;
      border-radius: 16px;
    }
  }
}
