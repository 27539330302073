.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 50px;

  .prev,
  .next {
    position: relative;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c1c5d6;
    border-radius: 50px;
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 14px;
      background: url('../../../../assets/images/dashboard/candidate/pagination.svg');
    }
  }

  .prev::after {
    background: url('../../../../assets/images/dashboard/candidate/pagination.svg');
    transform: rotate(180deg);
  }

  .active {
    background-color: #00e4ac;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .pagination {
    gap: 40px;
    margin-top: 0;
    padding-top: 24px;
    padding-bottom: 27px;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
