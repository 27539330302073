.Title {
  position: relative;
  border-bottom: 0.5px solid #c1c5d6;

  input {
    width: 100%;
    height: 43px;
    padding-right: 25px;
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    line-height: 16px;
    border: none;

    &::placeholder {
      color: #c1c5d6;
    }

    &:focus {
      outline: none;
    }
  }
  .closeIcon {
    position: absolute;
    top: 9.5px;
    right: 0;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.43304 3.73818L7.5621 0.609117C7.79092 0.380294 8.16192 0.380294 8.39074 0.609117C8.61957 0.83794 8.61957 1.20894 8.39074 1.43776L5.26168 4.56682L8.39074 7.69589C8.61956 7.92471 8.61956 8.29571 8.39074 8.52453C8.16192 8.75335 7.79092 8.75335 7.5621 8.52453L4.43303 5.39546L1.30397 8.52453C1.07515 8.75335 0.704152 8.75335 0.475329 8.52453C0.246506 8.29571 0.246506 7.92471 0.475329 7.69589L3.60439 4.56682L0.475328 1.43776C0.246505 1.20894 0.246505 0.83794 0.475328 0.609117C0.704151 0.380294 1.07515 0.380294 1.30397 0.609117L4.43304 3.73818Z' fill='%23242833'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .Title {
    input {
      height: 49px;
    }
  }
}
